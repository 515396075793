import axios from 'axios'
import { Service } from 'axios-middleware'

const service = new Service(axios)

service.register({
  onResponse(response) {
    if (response.status === 401) {
      dispatch('auth/resetUser')
    }
    return response
  },
})

export default {
  namespaced: true,
}
