import axios from 'axios'
import { eventBus } from '../../main'
import router from '../../router'

const localAxios = axios.create({
  baseURL: process.env.VUE_APP_AUTH || '',
})

let callComplete
let callReset
let callChangePass
let callChangeEmail

export default {
  namespaced: true,
  state: {

    rToken: localStorage.getItem('refreshToken') || '.',
    aToken: localStorage.getItem('accessToken') || '.',
    isLoggedIn: false,
    user: JSON.parse(atob((localStorage.getItem('accessToken') || '.').split('.')[1]) || '{}') || {},
    emailConfirmed: undefined,

    responses: {
      signUp: false,
      signupComplete: false,
      signOut: false,
      signIn: false,
      forgotPass: false,
      resetPass: false,
      changePass: false,
      confirmEmail: false,
    },

  },
  mutations: {

    SAVE_TOKENS(state, { aToken, rToken }) {
      state.aToken = aToken
      state.rToken = rToken
      localStorage.setItem('accessToken', aToken)
      localStorage.setItem('refreshToken', rToken)
    },

    SAVE_USER(state, data) {
      state.user = data
    },

    SAVE_LOGIN_STATE(state, currState) {
      state.isLoggedIn = currState
    },

    SAVE_RESPONSE_STATE(state, { type, currState }) {
      state.responses[type] = currState
    },

    SAVE_CONFIRM(state, data) {
      state.emailConfirmed = data
    },

  },
  actions: {

    signUp({ commit }, params) {
      commit('SAVE_RESPONSE_STATE', {
        type: 'signUp',
        currState: true,
      })

      localAxios
        .post(process.env.VUE_APP_REGISTER, params)

        .then(res => {
          eventBus.$emit('showToast', {
            title: res.data.result.message,
            variant: res.data.success ? 'success' : 'warning',
            icon: res.data.success ? 'CheckIcon' : 'AlertTriangleIcon',
          })
        })

        .catch(err => {
          console.error(err)

          eventBus.$emit('showToast', {
            title: 'Unexpected Error',
            variant: 'danger',
            icon: 'AlertOctagonIcon',
          })
        })

        .finally(() => {
          eventBus.$emit('resetCaptcha')

          commit('SAVE_RESPONSE_STATE', {
            type: 'signUp',
            currState: false,
          })
        })
    },

    signUpComplete({ commit }, params) {
      if (callComplete) {
        callComplete.cancel()
      }
      callComplete = axios.CancelToken.source()

      commit('SAVE_RESPONSE_STATE', {
        type: 'signupComplete',
        currState: true,
      })

      localAxios
        .post(process.env.VUE_APP_COMPLETE_REGISTER, params, { cancelToken: callComplete.token })

        .then(res => {
          const result = res.data.result

          if (result.status === 200) {
            commit('SAVE_TOKENS', {
              aToken: result.accessToken !== undefined ? result.accessToken : '',
              rToken: result.refreshToken !== undefined ? result.refreshToken : '',
            })

            commit('SAVE_USER', JSON.parse(atob((result.accessToken || '.').split('.')[1]) || '{}'))

            commit('SAVE_LOGIN_STATE', true)

            eventBus.$emit('getUser')
            eventBus.$emit('getOutdatedPairs')

            router.push({ path: '/' }).catch(err => {
              console.error(err)
            })
          } else {
            eventBus.$emit('showToast', {
              title: result.message,
              variant: 'warning',
              icon: 'AlertTriangleIcon',
            })
          }
        })

        .catch(err => {
          console.error(err)

          if (!axios.isCancel(err)) {
            eventBus.$emit('showToast', {
              title: 'Unexpected Error',
              variant: 'danger',
              icon: 'AlertOctagonIcon',
            })
          }
        })

        .finally(() => {
          commit('SAVE_RESPONSE_STATE', {
            type: 'signupComplete',
            currState: false,
          })
        })
    },

    signOut({ commit, state, dispatch }) {
      commit('SAVE_RESPONSE_STATE', {
        type: 'signOut',
        currState: true,
      })

      localAxios
        .post(process.env.VUE_APP_SIGNOUT, {}, { headers: { refreshToken: state.rToken } })

        .then(res => {
          const result = res.data.result

          if (result.status === 200) {
            dispatch('resetUser')
          } else {
            eventBus.$emit('showToast', {
              title: result.message,
              variant: 'warning',
              icon: 'AlertTriangleIcon',
            })
          }
        })

        .catch(err => {
          console.error(err)

          eventBus.$emit('showToast', {
            title: 'Unexpected Error',
            variant: 'danger',
            icon: 'AlertOctagonIcon',
          })
        })

        .finally(() => {
          commit('SAVE_RESPONSE_STATE', {
            type: 'signOut',
            currState: false,
          })
        })
    },

    resetUser({ commit }) {
      commit('SAVE_TOKENS', {
        aToken: '',
        rToken: '',
      })

      commit('SAVE_USER', {})
      commit('SAVE_LOGIN_STATE', false)

      router.push({ name: 'login' }).catch(err => {
        console.error(err)
      })
    },

    signIn({ commit }, params) {
      commit('SAVE_RESPONSE_STATE', {
        type: 'signIn',
        currState: true,
      })

      localAxios
        .post(process.env.VUE_APP_SIGNIN, params)

        .then(res => {
          const result = res.data.result

          if (result.status === 200) {
            commit('SAVE_TOKENS', {
              aToken: result.accessToken,
              rToken: result.refreshToken,
            })
            commit('SAVE_USER', JSON.parse(atob((result.accessToken || '.').split('.')[1]) || '{}'))
            commit('SAVE_LOGIN_STATE', true)

            eventBus.$emit('getUser')
            eventBus.$emit('getOutdatedPairs')

            router.push({ path: '/' }).catch(err => {
              console.error(err)
            })
          } else {
            eventBus.$emit('showToast', {
              title: result.message,
              variant: 'warning',
              icon: 'AlertTriangleIcon',
            })
          }
        })

        .catch(err => {
          console.error(err)

          eventBus.$emit('showToast', {
            title: 'Unexpected Error',
            variant: 'danger',
            icon: 'AlertOctagonIcon',
          })
        })

        .finally(() => {
          eventBus.$emit('resetCaptcha')

          commit('SAVE_RESPONSE_STATE', {
            type: 'signIn',
            currState: false,
          })
        })
    },

    forgotPass({ commit }, params) {
      commit('SAVE_RESPONSE_STATE', {
        type: 'forgotPass',
        currState: true,
      })

      localAxios
        .post(process.env.VUE_APP_FORGOT, params)

        .then(res => {
          eventBus.$emit('showToast', {
            title: res.data.result.message,
            variant: res.data.success ? 'success' : 'warning',
            icon: res.data.success ? 'CheckIcon' : 'AlertTriangleIcon',
          })
        })

        .catch(err => {
          console.error(err)

          eventBus.$emit('showToast', {
            title: 'Unexpected Error',
            variant: 'danger',
            icon: 'AlertOctagonIcon',
          })
        })

        .finally(() => {
          eventBus.$emit('resetCaptcha')

          commit('SAVE_RESPONSE_STATE', {
            type: 'forgotPass',
            currState: false,
          })
        })
    },

    resetPass({ commit }, params) {
      if (callReset) {
        callReset.cancel()
      }
      callReset = axios.CancelToken.source()

      commit('SAVE_RESPONSE_STATE', {
        type: 'resetPass',
        currState: true,
      })

      localAxios
        .post(process.env.VUE_APP_RESET, params, { cancelToken: callReset.token })

        .then(res => {
          const result = res.data.result

          if (result.status === 200) {
            router.push({ name: 'login' }).catch(err => {
              console.error(err)
            })
          } else {
            eventBus.$emit('showToast', {
              title: result.message,
              variant: 'warning',
              icon: 'AlertTriangleIcon',
            })
          }
        })

        .catch(err => {
          console.error(err)

          if (!axios.isCancel(err)) {
            eventBus.$emit('showToast', {
              title: 'Unexpected Error',
              variant: 'danger',
              icon: 'AlertOctagonIcon',
            })
          }
        })

        .finally(() => {
          commit('SAVE_RESPONSE_STATE', {
            type: 'resetPass',
            currState: false,
          })
        })
    },

    refreshTokens({ commit }) {
      return new Promise((resolve, reject) => {
        let promiseError
        localStorage.setItem('tokenPending', true)
        localAxios
          .post(process.env.VUE_APP_REFRESH, {}, {
            headers: {
              refreshtoken: localStorage.getItem('refreshToken'),
            },
          })
          .then(res => {
            const result = res.data.result

            if (result.status >= 200 && result.status < 400) {
              commit('SAVE_TOKENS', {
                aToken: result.accessToken,
                rToken: result.refreshToken,
              })

              commit('SAVE_USER', JSON.parse(atob((result.accessToken || '.').split('.')[1]) || '{}'))

              commit('SAVE_LOGIN_STATE', true)
              localStorage.removeItem('tokenPending')
              return resolve({
                rToken: result.refreshToken,
                aToken: result.accessToken,
              })
            } else {
              localStorage.removeItem('tokenPending')
              promiseError = res.data.result
              return reject(promiseError)
            }
          })
          .catch(err => {
            localStorage.removeItem('tokenPending')
            promiseError = err
            return reject(promiseError)
          })
      })
    },

    changePass({ commit }, { params, aToken }) {
      if (callChangePass) {
        callChangePass.cancel()
      }
      callChangePass = axios.CancelToken.source()

      commit('SAVE_RESPONSE_STATE', {
        type: 'changePass',
        currState: true,
      })

      localAxios
        .post(
          process.env.VUE_APP_CHANGE_PASS,
          params,
          {
            headers: {
              Authorization: `Bearer ${aToken}`,
            },
            cancelToken: callChangePass.token,
          },
        )

        .then(res => {
          const data = res.data

          eventBus.$emit('showToast/Profile', {
            title: data.result.message,
            variant: data.success ? 'success' : 'warning',
            icon: data.success ? 'CheckIcon' : 'AlertTriangleIcon',
          })

          commit('SAVE_RESPONSE_STATE', {
            type: 'changePass',
            currState: false,
          })
        })

        .catch(err => {
          console.error(err)

          if (!axios.isCancel(err)) {
            eventBus.$emit('showToast/Profile', {
              title: 'Unexpected Error',
              variant: 'danger',
              icon: 'AlertOctagonIcon',
            })
            commit('SAVE_RESPONSE_STATE', {
              type: 'changePass',
              currState: false,
            })
          } else {
            commit('SAVE_RESPONSE_STATE', {
              type: 'changePass',
              currState: true,
            })
          }
        })
    },

    changeEmail({ commit }, { params, aToken }) {
      if (callChangeEmail) {
        callChangeEmail.cancel()
      }
      callChangeEmail = axios.CancelToken.source()

      commit('SAVE_RESPONSE_STATE', {
        type: 'changeEmail',
        currState: true,
      })

      localAxios
        .post(
          process.env.VUE_APP_CHANGE_EMAIL,
          params,
          {
            headers: {
              Authorization: `Bearer ${aToken}`,
            },
            cancelToken: callChangeEmail.token,
          },
        )

        .then(res => {
          const data = res.data

          eventBus.$emit('showToast/Profile', {
            title: data.result.message,
            variant: data.success ? 'success' : 'warning',
            icon: data.success ? 'CheckIcon' : 'AlertTriangleIcon',
          })

          commit('SAVE_RESPONSE_STATE', {
            type: 'changeEmail',
            currState: false,
          })
        })

        .catch(err => {
          console.error(err)

          if (!axios.isCancel(err)) {
            eventBus.$emit('showToast/Profile', {
              title: 'Unexpected Error',
              variant: 'danger',
              icon: 'AlertOctagonIcon',
            })
            commit('SAVE_RESPONSE_STATE', {
              type: 'changeEmail',
              currState: false,
            })
          } else {
            commit('SAVE_RESPONSE_STATE', {
              type: 'changeEmail',
              currState: true,
            })
          }
        })
    },

    confirmEmail({ commit, dispatch }, { params, aToken }) {
      commit('SAVE_RESPONSE_STATE', {
        type: 'confirmEmail',
        currState: true,
      })

      localAxios
        .post(
          process.env.VUE_APP_CONFIRM,
          params,
          {
            headers: {
              Authorization: `Bearer ${aToken}`,
            },
          },
        )

        .then(res => {
          const data = res.data

          commit('SAVE_CONFIRM', data.success)

          if (data.success) {
            setTimeout(() => {
              dispatch('resetUser')
            }, 5000)
          }

          commit('SAVE_RESPONSE_STATE', {
            type: 'confirmEmail',
            currState: false,
          })
        })

        .catch(err => {
          console.error(err)

          eventBus.$emit('showToast', {
            title: 'Unexpected Error',
            variant: 'danger',
            icon: 'AlertOctagonIcon',
          })

          commit('SAVE_RESPONSE_STATE', {
            type: 'confirmEmail',
            currState: false,
          })
        })
    },

  },
}
