import axios from 'axios'
import { eventBus } from '../../main'

const localAxios = axios.create({
  baseURL: process.env.VUE_APP_AUTH || '',
})

function initialState() {
  return {
    subscription: {},
    profile: {},

    responses: {
      avatar: false,
      profile: false,
      subscription: false,
    },
  }
}

let callAvatar
let callProfile

export default {
  namespaced: true,
  state: initialState,
  mutations: {

    RESET_STATE(state) {
      const initial = initialState()
      Object.keys(initial).forEach(key => {
        state[key] = initial[key]
      })
    },

    SAVE_USER_SUBSCRIPTION(state, data) {
      state.subscription = data
    },

    SAVE_USER_PROFILE(state, data) {
      state.profile = data
    },

    SAVE_USER_AVATAR(state, data) {
      state.profile.photo = data
    },

    SAVE_RESPONSE_STATE(state, { type, currState }) {
      state.responses[type] = currState
    },

  },
  actions: {

    getSubscription({ commit }, aToken) {
      localAxios
        .get(
          process.env.VUE_APP_SUBSCRIPTION,
          {
            headers: {
              Authorization: `Bearer ${aToken}`,
            },
          },
        )

        .then(res => {
          const data = res.data

          commit('SAVE_RESPONSE_STATE', {
            type: 'subscription',
            currState: true,
          })

          if (data.success) {
            if (data.result.subscription !== null) {
              commit('SAVE_USER_SUBSCRIPTION', data.result.subscription)
            }
          } else {
            eventBus.$emit('showToast', {
              title: res.response.message,
              variant: 'warning',
              icon: 'AlertTriangleIcon',
            })
          }
        })

        .catch(err => {
          console.error(err)

          eventBus.$emit('showToast', {
            title: 'Unexpected Error',
            variant: 'danger',
            icon: 'AlertOctagonIcon',
          })
        })
    },

    getProfile({ commit }, aToken) {
      localAxios
        .get(
          process.env.VUE_APP_PROFILE,
          {
            headers: {
              Authorization: `Bearer ${aToken}`,
            },
          },
        )

        .then(res => {
          const data = res.data

          if (data.success) {
            if (data.result.subscription !== null) {
              commit('SAVE_USER_PROFILE', data.result)
            }
          } else {
            eventBus.$emit('showToast/Profile', {
              title: res.response.message,
              variant: 'warning',
              icon: 'AlertTriangleIcon',
            })
          }
        })

        .catch(err => {
          console.error(err)

          eventBus.$emit('showToast/Profile', {
            title: 'Unexpected Error',
            variant: 'danger',
            icon: 'AlertOctagonIcon',
          })
        })
    },

    saveProfile({ commit }, { params, aToken }) {
      if (callProfile) {
        callProfile.cancel()
      }
      callProfile = axios.CancelToken.source()

      commit('SAVE_RESPONSE_STATE', {
        type: 'profile',
        currState: true,
      })

      localAxios
        .post(
          process.env.VUE_APP_PROFILE,
          params,
          {
            headers: {
              Authorization: `Bearer ${aToken}`,
            },
            cancelToken: callProfile.token,
          },
        )

        .then(res => {
          const data = res.data

          eventBus.$emit('showToast/Profile', {
            title: data.result.message,
            variant: data.success ? 'success' : 'warning',
            icon: data.success ? 'CheckIcon' : 'AlertTriangleIcon',
          })

          commit('SAVE_RESPONSE_STATE', {
            type: 'profile',
            currState: false,
          })
        })

        .catch(err => {
          console.error(err)

          if (!axios.isCancel(err)) {
            eventBus.$emit('showToast/Profile', {
              title: 'Unexpected Error',
              variant: 'danger',
              icon: 'AlertOctagonIcon',
            })
            commit('SAVE_RESPONSE_STATE', {
              type: 'profile',
              currState: false,
            })
          } else {
            commit('SAVE_RESPONSE_STATE', {
              type: 'profile',
              currState: true,
            })
          }
        })
    },

    uploadAvatar({ commit }, { media, aToken }) {
      if (callAvatar) {
        callAvatar.cancel()
      }
      callAvatar = axios.CancelToken.source()

      commit('SAVE_RESPONSE_STATE', {
        type: 'avatar',
        currState: true,
      })

      localAxios
        .post(
          process.env.VUE_APP_UPLOAD_FILE,
          media,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${aToken}`,
            },
            cancelToken: callAvatar.token,
          },
        )

        .then(res => {
          const data = res.data

          if (data.success) {
            commit('SAVE_USER_AVATAR', data.result.url)

            eventBus.$emit('showToast/Profile', {
              title: 'Avatar updated',
              variant: 'success',
              icon: 'CheckIcon',
            })
          } else {
            eventBus.$emit('showToast', {
              title: data.result.message,
              variant: 'warning',
              icon: 'AlertTriangleIcon',
            })
          }

          commit('SAVE_RESPONSE_STATE', {
            type: 'avatar',
            currState: false,
          })
        })

        .catch(err => {
          console.error(err)

          if (!axios.isCancel(err)) {
            eventBus.$emit('showToast', {
              title: 'Unexpected Error',
              variant: 'danger',
              icon: 'AlertOctagonIcon',
            })
            commit('SAVE_RESPONSE_STATE', {
              type: 'avatar',
              currState: false,
            })
          } else {
            commit('SAVE_RESPONSE_STATE', {
              type: 'avatar',
              currState: true,
            })
          }
        })
    },

  },
}
