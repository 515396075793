import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import global from './modules/global'
import breakpoints from './modules/breakpoints'
import config from './modules/config'
import vmenu from './modules/vmenu'

import pairs from './modules/pairs'
import auth from './modules/auth'
import user from './modules/user'
import watchlist from './modules/watchlist'
import portfolio from './modules/portfolio'
import todo from './modules/todo'
import calendar from './modules/calendar'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    global,
    breakpoints,
    config,
    vmenu,

    pairs,
    auth,
    user,
    watchlist,
    portfolio,
    todo,
    calendar,
  },
  strict: process.env.DEV,
})
