<template>
  <div
    class="preloader d-flex justify-content-center align-items-center p-50 rounded-circle bg-primary"
    :class="{'preloader--revealed' : show }">
    <b-spinner variant="light" />
  </div>
</template>

<script>
import { BSpinner } from 'bootstrap-vue'

export default {
  name: 'AppPreloader',
  components: {
    BSpinner,
  },
  props: {
    show: Boolean,
  },
  data() {
    return {
      
    }
  },
  computed: {
    
  },
  mounted() {
    
  },
  methods: {
    
  },
}
</script>

<style lang="scss" scoped>
.preloader {
  position: fixed;
  visibility: hidden;
  right: 2rem;
  bottom: 2rem;
  z-index: 2000;
  opacity: 0;
  transition: all 0.5s ease;
  user-select: none;

  &--revealed {
    visibility: visible;
    opacity: 1;
  }
}
</style>
