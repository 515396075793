import axios from 'axios'
import { eventBus } from '../../main'

const localAxios = axios.create({
  baseURL: process.env.VUE_APP_TOOLS || '',
})

let callManageEvent
let callFetchEvents
let callRemoveEvent

export default {
  namespaced: true,
  state: {
    calendarOptions: [],
    selectedCalendars: ['Earnings', 'Economic', 'Forecast', 'Speech', 'Technical'],

    responses: {
      manageEvent: false,
      fetchEvents: false,
      removeEvent: false,
    },
  },
  getters: {},
  mutations: {
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },

    SAVE_CATEGORIES(state, data) {
      const calendarOptions = [
        { color: 'primary', label: 'Earnings' },
        { color: 'success', label: 'Economic' },
        { color: 'info', label: 'Forecast' },
        { color: 'warning', label: 'Speech' },
        { color: 'danger', label: 'Technical' },
      ]

      const categories = []

      for (let n = 0; n < data.length; n++) {
        const name = data[n].name

        categories.push({
          id: data[n].id,
          label: name,
          color: calendarOptions.filter(i => i.label === name)[0].color,
        })
      }

      const sortedCategories = categories.sort((a, b) => {
        if (a.id > b.id) { return 1 }
        if (a.id < b.id) { return -1 }
        return 0
      })

      state.calendarOptions = sortedCategories
    },

    SAVE_RESPONSE_STATE(state, { type, currState }) {
      state.responses[type] = currState
    },
  },
  actions: {
    getCategories({ commit }, aToken) {
      localAxios
        .get(
          process.env.VUE_APP_CALENDAR_CATEGORIES,
          {
            headers: {
              Authorization: `Bearer ${aToken}`,
            },
          },
        )

        .then(res => {
          const data = res.data

          if (data.success) {
            commit('SAVE_CATEGORIES', data.rows)
          } else {
            eventBus.$emit('showToast/Calendar', {
              title: data.result.message,
              variant: 'warning',
              icon: 'AlertTriangleIcon',
            })
          }
        })

        .catch(err => {
          console.error(err)

          eventBus.$emit('showToast/Calendar', {
            title: 'Unexpected Error',
            variant: 'danger',
            icon: 'AlertOctagonIcon',
          })
        })
    },

    manageEvent({ commit }, { method, params, aToken }) {
      if (callManageEvent) {
        callManageEvent.cancel()
      }
      callManageEvent = axios.CancelToken.source()

      commit('SAVE_RESPONSE_STATE', {
        type: 'manageEvent',
        currState: true,
      })

      return new Promise((resolve, reject) => {
        localAxios
          /* eslint-disable-next-line no-unexpected-multiline */
          [method](
            process.env.VUE_APP_CALENDAR,
            params,
            {
              headers: {
                Authorization: `Bearer ${aToken}`,
              },
              cancelToken: callManageEvent.token,
            },
          )

          .then(res => {
            const data = res.data

            if (data.success) {
              eventBus.$emit('showToast/Calendar', {
                title: method === 'post' ? 'New event added' : 'Event updated',
                variant: 'success',
                icon: 'CheckIcon',
              })
            } else {
              eventBus.$emit('showToast/Calendar', {
                title: data.result.message,
                variant: 'warning',
                icon: 'AlertTriangleIcon',
              })
            }

            commit('SAVE_RESPONSE_STATE', {
              type: 'manageEvent',
              currState: false,
            })

            resolve(res)
          })

          .catch(err => {
            console.error(err)

            if (!axios.isCancel(err)) {
              eventBus.$emit('showToast/Calendar', {
                title: 'Unexpected Error',
                variant: 'danger',
                icon: 'AlertOctagonIcon',
              })
              commit('SAVE_RESPONSE_STATE', {
                type: 'manageEvent',
                currState: false,
              })
            } else {
              commit('SAVE_RESPONSE_STATE', {
                type: 'manageEvent',
                currState: true,
              })
            }

            reject(err)
          })
      })
    },

    /* eslint-disable no-unused-vars */
    fetchEvents({ commit, state }, { aToken, from, to }) {
      commit('SAVE_RESPONSE_STATE', {
        type: 'fetchEvents',
        currState: true,
      })

      if (callFetchEvents) {
        callFetchEvents.cancel()
      }
      callFetchEvents = axios.CancelToken.source()

      function categoryIds() {
        const idSet = []

        for (let i = 0; i < state.selectedCalendars.length; i++) {
          const target = state.calendarOptions.filter(n => state.selectedCalendars[i] === n.label)[0]

          const foundId = target ? target.id : ''

          idSet.push(foundId)
        }

        return idSet.join(encodeURIComponent(','))
      }

      function toStringDate(ts) {
        return new Date(ts)
      }

      function getCategoryLabel(id) {
        return state.calendarOptions.filter(i => id === i.id)[0].label
      }

      function convertToCalendarApi(data) {
        // Back-end requires specific payload data fields
        // Vuexy front-end is too painfull to modify
        // So we just make little tweaks after receiving data from server
        const result = []

        for (let i = 0; i < data.length; i++) {
          result.push({
            id: data[i].id,
            title: data[i].title,
            allDay: data[i].all_day,
            start: toStringDate(data[i].start_date),
            end: toStringDate(data[i].end_date),
            url: data[i].eventUrl ? data[i].eventUrl : '',
            extendedProps: {
              calendar: getCategoryLabel(data[i].category_id),
              description: data[i].description ? data[i].description : '',
            },
          })
        }

        return result
      }

      return new Promise((resolve, reject) => {
        localAxios
          .get(
            `${process.env.VUE_APP_CALENDAR}?category_id=${categoryIds()}&from=${from}&to=${to}`,
            {
              headers: {
                Authorization: `Bearer ${aToken}`,
              },
              cancelToken: callFetchEvents.token,
            },
          )

          .then(res => {
            const data = res.data

            if (data.success) {
              console.log(convertToCalendarApi(data.rows))
              resolve(convertToCalendarApi(data.rows))
            } else {
              for (let i = 0; i < data.errors.length; i++) {
                eventBus.$emit('showToast/Calendar', {
                  message: data.errors[i],
                  type: 'error',
                })
              }
            }

            commit('SAVE_RESPONSE_STATE', {
              type: 'fetchEvents',
              currState: false,
            })
          })

          .catch(err => {
            console.error(err)

            if (!axios.isCancel(err)) {
              eventBus.$emit('showToast/Calendar', {
                title: 'Unexpected Error',
                variant: 'danger',
                icon: 'AlertOctagonIcon',
              })
              commit('SAVE_RESPONSE_STATE', {
                type: 'fetchEvents',
                currState: false,
              })
              reject(err)
            } else {
              commit('SAVE_RESPONSE_STATE', {
                type: 'fetchEvents',
                currState: true,
              })
            }
          })
      })
    },

    removeEvent({ commit }, { aToken, id }) {
      if (callRemoveEvent) {
        callRemoveEvent.cancel()
      }
      callRemoveEvent = axios.CancelToken.source()

      commit('SAVE_RESPONSE_STATE', {
        type: 'removeEvent',
        currState: true,
      })

      return new Promise((resolve, reject) => {
        localAxios
          .delete(
            `${process.env.VUE_APP_CALENDAR}/${id}`,
            {
              headers: {
                Authorization: `Bearer ${aToken}`,
              },
              cancelToken: callRemoveEvent.token,
            },
          )

          .then(res => {
            const data = res.data

            if (data.success) {
              eventBus.$emit('showToast/Calendar', {
                title: 'Event removed',
                variant: 'success',
                icon: 'CheckIcon',
              })

              resolve('success')
            } else {
              eventBus.$emit('showToast/Calendar', {
                title: data.result.message,
                variant: 'warning',
                icon: 'AlertTriangleIcon',
              })

              reject()
            }

            commit('SAVE_RESPONSE_STATE', {
              type: 'removeEvent',
              currState: false,
            })
          })

          .catch(err => {
            console.error(err)

            if (!axios.isCancel(err)) {
              eventBus.$emit('showToast/Calendar', {
                title: 'Unexpected Error',
                variant: 'danger',
                icon: 'AlertOctagonIcon',
              })
              commit('SAVE_RESPONSE_STATE', {
                type: 'removeEvent',
                currState: false,
              })
            } else {
              commit('SAVE_RESPONSE_STATE', {
                type: 'removeEvent',
                currState: true,
              })
            }

            reject(err)
          })
      })
    },
  },
}
