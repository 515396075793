// ====== IMPORT ====== //

// Core
import Vue from 'vue'
import axios from 'axios'
import VueCompositionAPI from '@vue/composition-api'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'
import { Vue2Storage } from 'vue2-storage'
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/tour'
import numeral from 'numeral'
import echarts from 'echarts'
import JsonCSV from 'vue-json-csv'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import AppPreloader from '@/views/components/AppPreloader.vue'
import AppItemList from '@/views/components/AppItemList.vue'
import sprite from '@/assets/images/icons/sprite.svg'
import darkTheme from '@/libs/themes/dark'
import lightTheme from '@/libs/themes/light'
import darkThemeMini from '@/libs/themes/dark-mini'
import lightThemeMini from '@/libs/themes/light-mini'
import recomendedTheme from '@/libs/themes/recomended'

import router from './router'
import store from './store'
import App from './App.vue'

/* eslint-disable import/prefer-default-export */
export const eventBus = new Vue()

/* eslint-disable quote-props */
export const sectorColors = {
  'None': '#A0A0A0',
  'Basic Materials': '#7367f0',
  'Communication Services': '#28c76f',
  'Consumer Cyclical': '#0F69FF',
  'Consumer Defensive': '#ff9f43',
  'Energy': '#ea5455',
  'Financial Services': '#82868b',
  'Healthcare': '#00cfe8',
  'Industrials': '#5F9AD5',
  'Real Estate': '#FF2F5F',
  'Technology': '#D59D5C',
  'Utilities': '#BD4D7C',
  'Metals Futures': '#B8CB31',
  'Enery Futures': '#FF28CD',
  'Grains Futures': '#DE0055',
  'Softs Futures': '#FF907D',
  'Indices Futures': '#FF37A8',
  'Currency': '#FF944D',
  'Crypto Currency': '#50DE50',
}

export const assetColors = {
  'Equity': '#7367f0',
  'Futures': '#FF2F5F',
}

export const screenerColors = {
  'Found Pairs': '#FF2F5F',
  'Rest Pairs': '#C7C7C7',
}

// ====== REGISTRATION ====== //

Vue.prototype.$echarts = echarts
Vue.prototype.$http = axios

Vue.component('downloadCsv', JsonCSV)
Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component('app-preloader', AppPreloader)
Vue.component('app-item-list', AppItemList)
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(numeral)
Vue.use(Vue2Storage, { prefix: `${$themeConfig.app.appAbbr}-` })
Vue.use(darkTheme, lightTheme, darkThemeMini, lightThemeMini, recomendedTheme)
Vue.use(PerfectScrollbar, {
  options: {
    wheelPropagation: false,
  },
})
Vue.use(VueCompositionAPI)
Vue.use(require('vue-moment'))

// SVG Sprite
fetch(sprite)
  .then(response => response.text())
  .then(svg => {
    const div = document.createElement('div')
    div.hidden = true
    div.insertAdjacentHTML('afterbegin', svg)
    document.body.insertBefore(div, document.body.childNodes[0])
  })

// ====== INITIALISATION ====== //

require('@core/scss/core.scss')
require('@/assets/scss/style.scss')

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
