import axios from 'axios'
import { eventBus } from '../../main'
import router from '../../router'

const localAxios = axios.create({
  baseURL: process.env.VUE_APP_HTTP || '',
})

function initialState() {
  return {
    params: {
      search: '',
      cros_sector: true,
      mcap: 1,
      sector: 1,
      corelation: null,
      corelation_3y: null,
      cointegration: null,
      cointegration_3y: null,
      stationarity: null,
      stationarity_3y: null,
      count: 30, // static value
      page: 1,
    },

    mcaps: {},
    sectors: {},

    pairs: [],
    recomendedPairs: [],
    outdatedPairs: [],
    pairsResponse: false,
    countPairs: 0,
    totalPairs: 0,
    lastUpdate: '',

    searchResults: [],

    selectedPair: {},
    pairData: [{}, {}],
    pairStats: {},

    shareLink: '',

    klines: {
      '1y': {},
      '3y': {},
      '10y': {},
    },

    responses: {
      pairs: false,
      klines: false,
      screenshot: false,
    },
  }
}

let callSearchQuery
let callPairs
let callKlines
let callScreenshot

export default {
  namespaced: true,
  state: initialState,
  mutations: {

    RESET_STATE(state) {
      const initial = initialState()
      Object.keys(initial).forEach(key => {
        state[key] = initial[key]
      })
    },

    SAVE_VOCABS(state, data) {
      // Replace standart Object params "name" & "id" => "text" & "value"
      // so Vuexy select can properly work with data
      Object.keys(data).forEach(key => {
        for (let i = 0; i < data[key].length; i++) {
          Object.keys(data[key][i]).forEach(param => {
            if (param === 'name') {
              data[key][i].text = data[key][i].name
            }
            if (param === 'id') {
              data[key][i].value = data[key][i].id
            }
          })
          delete data[key][i].name
          delete data[key][i].id
        }

        state[key] = data[key]
      })
    },

    CLEAR_LIVE_SEARCH(state) {
      state.searchResults = []
    },

    SAVE_LIVE_SEARCH(state, data) {
      const results = []

      for (let i = 0; i < data.length; i++) {
        results.push({
          label: data[i].ticker,
          ticker: data[i].ticker,
          company: data[i].name,
        })
      }

      console.log(results)

      state.searchResults = results
    },

    SAVE_PAIRS(state, data) {
      // Calculate OLS
      for (let i = 0; i < data.length; i++) {
        data[i].ols = ((data[i].y_price - data[i].coef * data[i].x_price) / (data[i].coef * data[i].x_price)) * 100
        data[i].ols_3y = ((data[i].y_price - data[i].coef_3y * data[i].x_price) / (data[i].coef_3y * data[i].x_price)) * 100
      }

      state.pairs = data
    },

    SAVE_RECOMENDED_PAIRS(state, data) {
      state.recomendedPairs = data
    },

    SAVE_OUTDATED_PAIRS(state, data) {
      state.outdatedPairs = data
    },

    SAVE_PAIRS_RESPONSE_STATE(state, data) {
      state.pairsResponse = data
    },

    SAVE_COUNT_PAIRS(state, data) {
      state.countPairs = data
    },

    SAVE_TOTAL_PAIRS(state, data) {
      state.totalPairs = data
    },

    SAVE_LAST_UPDATE(state, data) {
      state.lastUpdate = data
    },

    SAVE_RESPONSE_STATE(state, { type, currState }) {
      state.responses[type] = currState
    },

    SAVE_KILNES(state, { category, data }) {
      state.klines[category] = data
    },

    SAVE_PAIR_DATA(state, data) {
      state.selectedPair = data

      // Make separated rows for vue-good-table
      const prefix = ['x_', 'y_']
      const modifiedData = [{}, {}]

      for (let i = 0; i < prefix.length; i++) {
        Object.keys(data).forEach(key => {
          if (key.startsWith(prefix[i])) {
            modifiedData[i][key.replace(prefix[i], '')] = data[key]
          }
        })
      }

      state.pairData = modifiedData
    },

    ADD_PORTFOLIO_DATA(state, data) {
      Object.keys(data).forEach(key => {
        state.selectedPair[key] = data[key]
      })

      // Make separated rows for vue-good-table
      const prefix = ['x_', 'y_']

      for (let i = 0; i < prefix.length; i++) {
        Object.keys(data).forEach(key => {
          if (key.startsWith(prefix[i])) {
            state.pairData[i][key.replace(prefix[i], '')] = data[key]
          }
        })
      }
    },

    SAVE_PAIR_STATS(state, data) {
      // It is not strongly necessary
      // But let's save only needed portion of data
      // You can remove that later if you need so
      // And just use `state.pairStats = data`
      const income = {}
      Object.assign(income, data)

      Object.keys(income).forEach(key => {
        if (key.startsWith('x_') || key.startsWith('y_')) {
          delete income[key]
        }
      })

      state.pairStats = income
    },

    SAVE_SHARE_LINK(state, data) {
      state.shareLink = data
    },

    SAVE_WATCHLIST_STATE(state, data) {
      state.selectedPair.in_watch_list = data
    },

    SAVE_PORTFOLIO_STATE(state, data) {
      if (data.in_portfolio) {
        state.selectedPair.in_portfolio = true

        Object.keys(data.data).forEach(key => {
          state.selectedPair[key] = data.data[key]
        })
      } else {
        state.selectedPair.in_portfolio = false
      }
    },

  },
  actions: {

    getVocabs({ commit }, aToken) {
      localAxios
        .post(
          process.env.VUE_APP_VOCABS,
          {},
          {
            headers: {
              Authorization: `Bearer ${aToken}`,
            },
          },
        )

        .then(res => {
          const data = res.data

          if (data.success) {
            commit('SAVE_VOCABS', data.result)
          } else {
            eventBus.$emit('showToast', {
              title: data.result.message,
              variant: 'warning',
              icon: 'AlertTriangleIcon',
            })
          }
        })

        .catch(err => {
          console.error(err)

          eventBus.$emit('showToast', {
            title: 'Unexpected Error',
            variant: 'danger',
            icon: 'AlertOctagonIcon',
          })
        })
    },

    async searchQuery({ commit }, { aToken, query }) {
      return new Promise((resolve, reject) => {
        if (callSearchQuery) {
          callSearchQuery.cancel()
        }
        callSearchQuery = axios.CancelToken.source()

        commit('CLEAR_LIVE_SEARCH')

        localAxios
          .get(
            `${process.env.VUE_APP_LIVE_SEARCH}?q=${query}`,
            {
              headers: {
                Authorization: `Bearer ${aToken}`,
              },
              cancelToken: callSearchQuery.token,
            },
          )

          .then(res => {
            const data = res.data

            if (data.success) {
              commit('SAVE_LIVE_SEARCH', data.result)

              resolve('success')
            } else {
              reject()
            }
          })

          .catch(err => {
            console.error(err)

            if (!axios.isCancel(err)) {
              eventBus.$emit('showToast', {
                title: 'Unexpected Error',
                variant: 'danger',
                icon: 'AlertOctagonIcon',
              })
            }

            reject(err)
          })
      })
    },

    getPairs({ commit }, { params, aToken }) {
      if (callPairs) {
        callPairs.cancel()
      }
      callPairs = axios.CancelToken.source()

      commit('SAVE_RESPONSE_STATE', {
        type: 'pairs',
        currState: true,
      })

      localAxios
        .post(
          process.env.VUE_APP_PAIRS,
          params,
          {
            headers: {
              Authorization: `Bearer ${aToken}`,
            },
            cancelToken: callPairs.token,
          },
        )

        .then(res => {
          const data = res.data

          if (data.success) {
            commit('SAVE_PAIRS', data.result)
            commit('SAVE_PAIRS_RESPONSE_STATE', true)
            commit('SAVE_RESPONSE_STATE', {
              type: 'pairs',
              currState: false,
            })
          } else {
            eventBus.$emit('showToast', {
              title: data.result.message,
              variant: 'warning',
              icon: 'AlertTriangleIcon',
            })
            commit('SAVE_RESPONSE_STATE', {
              type: 'pairs',
              currState: false,
            })
          }

          commit('SAVE_RESPONSE_STATE', {
            type: 'pairs',
            currState: false,
          })
        })

        .catch(err => {
          console.error(err)

          if (!axios.isCancel(err)) {
            eventBus.$emit('showToast', {
              title: 'Unexpected Error',
              variant: 'danger',
              icon: 'AlertOctagonIcon',
            })
            commit('SAVE_RESPONSE_STATE', {
              type: 'pairs',
              currState: false,
            })
          } else {
            commit('SAVE_RESPONSE_STATE', {
              type: 'pairs',
              currState: true,
            })
          }
        })
    },

    getRecomendedPairs({ commit }, aToken) {
      localAxios
        .get(
          process.env.VUE_APP_RECOMENDED_PAIRS,
          {
            headers: {
              Authorization: `Bearer ${aToken}`,
            },
          },
        )

        .then(res => {
          const data = res.data

          if (data.success) {
            commit('SAVE_RECOMENDED_PAIRS', data.result)
          }
        })

        .catch(err => {
          console.error(err)
        })
    },

    getOutdatedPairs({ commit }, aToken) {
      localAxios
        .get(
          process.env.VUE_APP_OUTDATES_PAIRS,
          {
            headers: {
              Authorization: `Bearer ${aToken}`,
            },
          },
        )

        .then(res => {
          const data = res.data

          if (data.success) {
            commit('SAVE_OUTDATED_PAIRS', data.result)
          } else {
            eventBus.$emit('showToast', {
              title: data.result.message,
              variant: 'warning',
              icon: 'AlertTriangleIcon',
            })
          }
        })

        .catch(err => {
          console.error(err)

          eventBus.$emit('showToast', {
            title: 'Unexpected Error',
            variant: 'danger',
            icon: 'AlertOctagonIcon',
          })
        })
    },

    getCountPairs({ commit }, { params, aToken }) {
      localAxios
        .post(
          process.env.VUE_APP_COUNT_PAIRS,
          params,
          {
            headers: {
              Authorization: `Bearer ${aToken}`,
            },
          },
        )

        .then(res => {
          const data = res.data

          if (data.success) {
            commit('SAVE_COUNT_PAIRS', data.result.count)
          } else {
            eventBus.$emit('showToast', {
              title: data.result.message,
              variant: 'warning',
              icon: 'AlertTriangleIcon',
            })
          }
        })

        .catch(err => {
          console.error(err)

          eventBus.$emit('showToast', {
            title: 'Unexpected Error',
            variant: 'danger',
            icon: 'AlertOctagonIcon',
          })
        })
    },

    getTotalPairs({ commit }, aToken) {
      localAxios
        .post(
          process.env.VUE_APP_TOTAL_PAIRS,
          {},
          {
            headers: {
              Authorization: `Bearer ${aToken}`,
            },
          },
        )

        .then(res => {
          const data = res.data

          if (data.success) {
            commit('SAVE_TOTAL_PAIRS', data.result.count)
            commit('SAVE_LAST_UPDATE', data.result.last_update)
          } else {
            eventBus.$emit('showToast', {
              title: data.result.message,
              variant: 'warning',
              icon: 'AlertTriangleIcon',
            })
          }
        })

        .catch(err => {
          console.error(err)

          eventBus.$emit('showToast', {
            title: 'Unexpected Error',
            variant: 'danger',
            icon: 'AlertOctagonIcon',
          })
        })
    },

    /* eslint-disable camelcase */
    async getKlines({ commit }, { params, coef_10y, aToken }) {
      if (callPairs) {
        callPairs.cancel()
        commit('SAVE_RESPONSE_STATE', {
          type: 'pairs',
          currState: false,
        })
      }
      callPairs = axios.CancelToken.source()

      if (callKlines) {
        callKlines.cancel()
      }
      callKlines = axios.CancelToken.source()

      commit('SAVE_RESPONSE_STATE', {
        type: 'klines',
        currState: true,
      })

      // Clear previous klines data
      const categories = ['1y', '3y', '10y']

      for (let i = 0; i < categories.length; i++) {
        commit('SAVE_KILNES', {
          category: categories[i],
          data: {},
        })
      }

      // Create new requests
      const requests = [
        {
          prefix: '1y',
          url: process.env.VUE_APP_KLINES_1Y,
          avaialable: true,
        },
        {
          prefix: '3y',
          url: process.env.VUE_APP_KLINES_3Y,
          avaialable: true,
        },
        {
          prefix: '10y',
          url: process.env.VUE_APP_KLINES_10Y,
          /* eslint-disable no-unneeded-ternary */
          avaialable: coef_10y !== null ? true : false,
        },
      ]

      const answers = []

      for (let i = 0; i < requests.length; i++) {
        if (requests[i].avaialable) {
          /* eslint-disable no-loop-func */
          const answer = new Promise((resolve, reject) => {
            localAxios
              .post(
                requests[i].url,
                params,
                {
                  headers: {
                    Authorization: `Bearer ${aToken}`,
                  },
                  cancelToken: callKlines.token,
                },
              )

              .then(res => {
                const data = res.data

                if (data.success) {
                  commit('SAVE_KILNES', {
                    category: requests[i].prefix,
                    data: data.result,
                  })
                  resolve('success')
                } else {
                  eventBus.$emit('showToast', {
                    title: data.result.message,
                    variant: 'warning',
                    icon: 'AlertTriangleIcon',
                  })
                  reject()
                }
              })

              .catch(err => {
                console.error(err)

                reject(err)
              })
          })

          answers.push(answer)
        }
      }

      return Promise.all(answers)
    },

    async savePair({ commit }, pairData) {
      return new Promise((resolve, reject) => {
        commit('SAVE_PAIR_DATA', pairData)
        commit('SAVE_PAIR_STATS', pairData)

        if (Object.keys(pairData).length > 0) {
          resolve('success')
        } else {
          reject()
        }
      })
    },

    async checkWatchListStats({ commit }, { params, aToken }) {
      return new Promise((resolve, reject) => {
        localAxios
          .post(
            process.env.VUE_APP_WATCHLIST_STATUS,
            params,
            {
              headers: {
                Authorization: `Bearer ${aToken}`,
              },
              cancelToken: callPairs.token,
            },
          )

          .then(res => {
            const data = res.data

            if (data.success) {
              commit('SAVE_WATCHLIST_STATE', data.result.in_watch_list)

              resolve('success')
            } else {
              reject()
            }
          })

          .catch(err => {
            console.error(err)

            if (!axios.isCancel(err)) {
              eventBus.$emit('showToast', {
                title: 'Unexpected Error',
                variant: 'danger',
                icon: 'AlertOctagonIcon',
              })
            }

            reject(err)
          })
      })
    },

    async checkPortfolioStats({ commit }, { params, aToken }) {
      return new Promise((resolve, reject) => {
        localAxios
          .post(
            process.env.VUE_APP_PORTFOLIO_STATUS,
            params,
            {
              headers: {
                Authorization: `Bearer ${aToken}`,
              },
              cancelToken: callPairs.token,
            },
          )

          .then(res => {
            const data = res.data

            if (data.success) {
              commit('SAVE_PORTFOLIO_STATE', data.result)

              if (data.result.in_portfolio) {
                commit('ADD_PORTFOLIO_DATA', data.result.data)
              }

              resolve('success')
            } else {
              reject()
            }
          })

          .catch(err => {
            console.error(err)

            if (!axios.isCancel(err)) {
              eventBus.$emit('showToast', {
                title: 'Unexpected Error',
                variant: 'danger',
                icon: 'AlertOctagonIcon',
              })
            }

            reject(err)
          })
      })
    },

    async showDetails(
      { dispatch, commit },
      {
        params,
        pairData,
        coef_10y,
        aToken,
      },
    ) {
      Promise.all([
        dispatch('getKlines', {
          params: {
            x: params.x,
            y: params.y,
          },
          coef_10y: coef_10y,
          aToken: aToken,
        }),
        dispatch('savePair', pairData),
        dispatch('checkWatchListStats', {
          params: {
            x: params.x,
            y: params.y,
          },
          aToken: aToken,
        }),
        dispatch('checkPortfolioStats', {
          params: {
            x: params.x,
            y: params.y,
          },
          aToken: aToken,
        }),
      ])

        .then(result => {
          function reponsesSuccess() {
            return result[0].every(elem => elem === 'success')
            && result[1] === 'success'
            && result[2] === 'success'
            && result[3] === 'success'
          }

          if (reponsesSuccess()) {
            commit('SAVE_RESPONSE_STATE', {
              type: 'klines',
              currState: false,
            })

            router.push({ path: '/analysis/details' }).catch(err => {
              console.error(err)
            })
          }
        })

        .catch(err => {
          console.error(err)

          if (!axios.isCancel(err)) {
            eventBus.$emit('showToast', {
              title: 'Unexpected Error',
              variant: 'danger',
              icon: 'AlertOctagonIcon',
            })
          }
        })
    },

    sendScreenshot({ commit }, {
      formData, theme, aToken, params,
    }) {
      if (callScreenshot) {
        callScreenshot.cancel()
      }
      callScreenshot = axios.CancelToken.source()

      localAxios
        .post(
          process.env.VUE_APP_SCREENSHOT,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${aToken}`,
            },
            cancelToken: callScreenshot.token,
          },
        )

        .then(res => {
          const data = res.data

          if (data.success) {
            if (params) {
              commit('SAVE_SHARE_LINK', `${process.env.VUE_APP_SHARE}/${params.x_ticker}-${params.y_ticker}-corr-coint-1-3-yr-period&screen=${data.result.id}&theme=${theme}&p1=${params.x_ticker}&p2=${params.y_ticker}&adf=${params.df.toFixed(3)}&cor=${params.corel_3y.toFixed(3)}&ind1=${params.x_industry}&ind2=${params.y_industry}`)
            } else {
              commit('SAVE_SHARE_LINK', `${process.env.VUE_APP_SHARE}/?screen=${data.result.id}&theme=${theme}`)
            }
            eventBus.$emit('showPopover')
          } else {
            eventBus.$emit('showToast', {
              title: data.result.message,
              variant: 'warning',
              icon: 'AlertTriangleIcon',
            })
          }

          commit('SAVE_RESPONSE_STATE', {
            type: 'screenshot',
            currState: false,
          })
        })

        .catch(err => {
          console.error(err)

          if (!axios.isCancel(err)) {
            eventBus.$emit('showToast', {
              title: 'Unexpected Error',
              variant: 'danger',
              icon: 'AlertOctagonIcon',
            })
            commit('SAVE_RESPONSE_STATE', {
              type: 'screenshot',
              currState: false,
            })
          } else {
            commit('SAVE_RESPONSE_STATE', {
              type: 'screenshot',
              currState: true,
            })
          }
        })
    },

  },
}
