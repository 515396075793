import axios from 'axios'
import { eventBus } from '../../main'

const localAxios = axios.create({
  baseURL: process.env.VUE_APP_TOOLS || '',
})

function initialGetParams() {
  return {
    page: 1,
    limit: 50,
    mode: 'push',
    filterBy: undefined,
    category_id: undefined,
    sortBy: undefined,
    sortOrder: undefined,
    search: undefined,
    deleted: false,
  }
}

function initialState() {
  return {
    tasks: [],
    tasksTotal: undefined,
    filters: [
      { title: 'Important', icon: 'StarIcon', query: 'isImportant' },
      // { title: 'Unfinished', icon: 'ClockIcon', query: 'isUnfinished' },
      { title: 'Completed', icon: 'CheckIcon', query: 'isChecked' },
      { title: 'Archieved', icon: 'ArchiveIcon', query: 'deleted' },
    ],
    sorters: [
      { title: 'Title (A → Z)', sortBy: 'title', sortOrder: 'ASC' },
      { title: 'Title (Z → A)', sortBy: 'title', sortOrder: 'DESC' },
      { title: 'Due Date (Nearest)', sortBy: 'deadLine', sortOrder: 'ASC' },
      { title: 'Due Date (Farthest)', sortBy: 'deadLine', sortOrder: 'DESC' },
    ],
    categories: [],
    getParams: initialGetParams(),
    responses: {
      getTasks: false,
      manageTask: false,
      archieveTask: false,
    },
  }
}

let callManageTask
let callArchieveTask

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    SAVE_CATEGORIES(state, data) {
      const categoryColors = [
        { label: 'Learning', color: 'primary' },
        { label: 'Research', color: 'success' },
        { label: 'Opportunities', color: 'warning' },
        { label: 'Notice', color: 'danger' },
        { label: 'Other', color: 'info' },
      ]

      const categories = []

      for (let n = 0; n < data.length; n++) {
        const name = data[n].name

        categories.push({
          id: data[n].id,
          title: name,
          color: categoryColors.filter(i => i.label === name)[0].color,
        })
      }

      const sortedCategories = categories.sort((a, b) => {
        if (a.id > b.id) { return 1 }
        if (a.id < b.id) { return -1 }
        return 0
      })

      state.categories = sortedCategories
    },

    SAVE_TASKS(state, { data, mode }) {
      if (mode === 'push') {
        for (let i = 0; i < data.data.length; i++) {
          state.tasks.push(data.data[i])
        }
      } else if (mode === 'refresh') {
        state.tasks = data.data
      }

      state.tasksTotal = data.total
    },

    SAVE_FILTER_PARAMS(state, { type, query }) {
      state.getParams.filterBy = undefined
      state.getParams.category_id = undefined
      state.getParams.deleted = undefined

      state.getParams[type] = query
    },

    SAVE_SORT_PARAMS(state, params) {
      Object.keys(params).forEach(key => {
        if (key in state.getParams) {
          state.getParams[key] = params[key]
        }
      })
    },

    SAVE_SEARCH_QUERY(state, data) {
      state.getParams.search = data.length > 0 ? data : undefined
    },

    CHANGE_PAGE(state, data) {
      state.getParams.page = data
    },

    RESET_GET_PARAMS(state) {
      state.getParams = initialGetParams()
    },

    RESET_SORT_PARAMS(state) {
      state.getParams.sortBy = undefined
      state.getParams.sortOrder = undefined
    },

    SAVE_RESPONSE_STATE(state, { type, currState }) {
      state.responses[type] = currState
    },
  },
  actions: {
    getCategories({ commit }, aToken) {
      localAxios
        .get(
          process.env.VUE_APP_TODO_CATEGORIES,
          {
            headers: {
              Authorization: `Bearer ${aToken}`,
            },
          },
        )

        .then(res => {
          const data = res.data

          if (data.success) {
            commit('SAVE_CATEGORIES', data.rows)
          } else {
            eventBus.$emit('showToast/Todo', {
              title: data.result.message,
              variant: 'warning',
              icon: 'AlertTriangleIcon',
            })
          }
        })

        .catch(err => {
          console.error(err)

          eventBus.$emit('showToast/Todo', {
            title: 'Unexpected Error',
            variant: 'danger',
            icon: 'AlertOctagonIcon',
          })
        })
    },

    manageTask({ commit, dispatch }, { method, params, aToken }) {
      if (callManageTask) {
        callManageTask.cancel()
      }
      callManageTask = axios.CancelToken.source()

      commit('SAVE_RESPONSE_STATE', {
        type: 'manageTask',
        currState: true,
      })

      const request = new Promise((resolve, reject) => {
        localAxios
          /* eslint-disable-next-line no-unexpected-multiline */
          [method](
            process.env.VUE_APP_TODO,
            params,
            {
              headers: {
                Authorization: `Bearer ${aToken}`,
              },
              cancelToken: callManageTask.token,
            },
          )

          .then(res => {
            const data = res.data

            if (data.success) {
              resolve('success')
            } else {
              reject()
              eventBus.$emit('showToast/Todo', {
                title: data.result.message,
                variant: 'warning',
                icon: 'AlertTriangleIcon',
              })
            }

            commit('SAVE_RESPONSE_STATE', {
              type: 'manageTask',
              currState: false,
            })
          })

          .catch(err => {
            console.error(err)

            if (!axios.isCancel(err)) {
              eventBus.$emit('showToast/Todo', {
                title: 'Unexpected Error',
                variant: 'danger',
                icon: 'AlertOctagonIcon',
              })
              commit('SAVE_RESPONSE_STATE', {
                type: 'manageTask',
                currState: false,
              })
            } else {
              commit('SAVE_RESPONSE_STATE', {
                type: 'manageTask',
                currState: true,
              })
            }

            reject(err)
          })
      })

      request
        .then(result => {
          if (result === 'success') {
            dispatch('getTasks', {
              aToken: aToken,
              mode: 'refresh',
            })
          }
        })

        .catch(err => {
          console.error(err)
        })
    },

    getTasks({ commit, state }, { aToken, mode }) {
      commit('SAVE_RESPONSE_STATE', {
        type: 'getTasks',
        currState: true,
      })

      function apiLinkParams() {
        const arr = []
        const param = state.getParams

        arr.push(`page=${param.page}`)
        arr.push(`limit=${param.limit}`)

        if (param.filterBy) {
          arr.push(`filterBy=${param.filterBy}`)
        }
        if (param.deleted) {
          arr.push('deleted=true')
        }
        if (param.category_id) {
          arr.push(`category=${param.category_id}`)
        }
        if (param.sortBy) {
          arr.push(`sortBy=${param.sortBy}`)
        }
        if (param.sortOrder) {
          arr.push(`sortOrder=${param.sortOrder}`)
        }
        if (param.search) {
          arr.push(`search=${param.search}`)
        }

        return `${arr.join('&')}`
      }

      localAxios
        .get(
          `${process.env.VUE_APP_TODO}?${apiLinkParams()}`,
          {
            headers: {
              Authorization: `Bearer ${aToken}`,
            },
          },
        )

        .then(res => {
          const data = res.data

          if (data.success) {
            commit('SAVE_TASKS', {
              data: data.rows,
              mode: mode,
            })
          } else {
            for (let i = 0; i < data.errors.length; i++) {
              eventBus.$emit('showToast/Todo', {
                message: data.errors[i],
                type: 'error',
              })
            }
          }

          commit('SAVE_RESPONSE_STATE', {
            type: 'getTasks',
            currState: false,
          })
        })

        .catch(err => {
          console.error(err)

          eventBus.$emit('showToast/Todo', {
            title: 'Unexpected Error',
            variant: 'danger',
            icon: 'AlertOctagonIcon',
          })

          commit('SAVE_RESPONSE_STATE', {
            type: 'getTasks',
            currState: false,
          })
        })
    },

    archieveTask({ commit, dispatch }, { aToken, id }) {
      if (callArchieveTask) {
        callArchieveTask.cancel()
      }
      callArchieveTask = axios.CancelToken.source()

      commit('SAVE_RESPONSE_STATE', {
        type: 'archieveTask',
        currState: true,
      })

      const request = new Promise((resolve, reject) => {
        localAxios
          .delete(
            `${process.env.VUE_APP_TODO}/${id}`,
            {
              headers: {
                Authorization: `Bearer ${aToken}`,
              },
              cancelToken: callArchieveTask.token,
            },
          )

          .then(res => {
            const data = res.data

            if (data.success) {
              resolve('success')
            } else {
              reject()
              eventBus.$emit('showToast/Todo', {
                title: data.result.message,
                variant: 'warning',
                icon: 'AlertTriangleIcon',
              })
            }

            commit('SAVE_RESPONSE_STATE', {
              type: 'archieveTask',
              currState: false,
            })
          })

          .catch(err => {
            console.error(err)

            if (!axios.isCancel(err)) {
              eventBus.$emit('showToast/Todo', {
                title: 'Unexpected Error',
                variant: 'danger',
                icon: 'AlertOctagonIcon',
              })
              commit('SAVE_RESPONSE_STATE', {
                type: 'archieveTask',
                currState: false,
              })
            } else {
              commit('SAVE_RESPONSE_STATE', {
                type: 'archieveTask',
                currState: true,
              })
            }

            reject(err)
          })
      })

      request
        .then(result => {
          if (result === 'success') {
            dispatch('getTasks', {
              aToken: aToken,
              mode: 'refresh',
            })
          }
        })

        .catch(err => {
          console.error(err)
        })
    },

  },
}
