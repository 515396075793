import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', name: 'index', redirect: '/dashboard' },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('@/views/Dashboard.vue'),
      meta: {
        auth: true,
        pageTitle: 'Dashboard',
        breadcrumb: [
          {
            text: 'Dashboard',
            active: true,
          },
        ],
        navActiveLink: 'dashboard',
      },
    },
    {
      path: '/apps/todo',
      name: 'apps-todo',
      component: () => import('@/views/apps/todo/Todo.vue'),
      meta: {
        auth: true,
        contentRenderer: 'sidebar-left',
        contentClass: 'todo-application',
      },
    },
    {
      path: '/apps/calendar',
      name: 'apps-calendar',
      component: () => import('@/views/apps/calendar/Calendar.vue'),
      meta: {
        auth: true,
      },
    },
    {
      path: '/analysis',
      name: 'analysis',
      component: () => import('@/views/Analysis.vue'),
      meta: {
        auth: true,
        pageTitle: 'Analysis',
        breadcrumb: [
          {
            text: 'Analysis',
            active: true,
          },
        ],
        navActiveLink: 'analysis',
      },
    },
    {
      path: '/analysis/details',
      name: 'details',
      component: () => import('@/views/Details.vue'),
      meta: {
        auth: true,
        pageTitle: 'Details',
        breadcrumb: [
          {
            text: 'Details',
            active: true,
          },
        ],
        navActiveLink: 'analysis',
      },
    },
    {
      path: '/portfolio',
      name: 'portfolio',
      component: () => import('@/views/Portfolio.vue'),
      meta: {
        auth: true,
        pageTitle: 'Portfolio',
        breadcrumb: [
          {
            text: 'Portfolio',
            active: true,
          },
        ],
      },
    },
    {
      path: '/watchlist',
      name: 'watchlist',
      component: () => import('@/views/WatchList.vue'),
      meta: {
        auth: true,
        pageTitle: 'WatchList',
        breadcrumb: [
          {
            text: 'WatchList',
            active: true,
          },
        ],
      },
    },
    {
      path: '/subscription',
      name: 'subscription',
      component: () => import('@/views/Subscription.vue'),
      meta: {
        auth: true,
        pageTitle: 'Subscription',
        breadcrumb: [
          {
            text: 'Subscription',
            active: true,
          },
        ],
      },
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/Profile.vue'),
      meta: {
        auth: true,
        pageTitle: 'Profile',
        breadcrumb: [
          {
            text: 'Profile',
            active: true,
          },
        ],
      },
    },
    {
      path: '/auth/login',
      name: 'login',
      component: () => import('@/views/AuthSignIn.vue'),
      meta: {
        auth: false,
        layout: 'full',
      },
    },
    {
      path: '/auth/register',
      name: 'register',
      component: () => import('@/views/AuthSignUp.vue'),
      meta: {
        auth: false,
        layout: 'full',
      },
    },
    {
      path: '/auth/complete',
      name: 'complete',
      component: () => import('@/views/AuthComplete.vue'),
      meta: {
        auth: false,
        layout: 'full',
      },
    },
    {
      path: '/auth/forgot',
      name: 'forgot',
      component: () => import('@/views/AuthForgot.vue'),
      meta: {
        auth: false,
        layout: 'full',
      },
    },
    {
      path: '/auth/reset',
      name: 'reset',
      component: () => import('@/views/AuthReset.vue'),
      meta: {
        auth: false,
        layout: 'full',
      },
    },
    {
      path: '/auth/confirm',
      name: 'reset',
      component: () => import('@/views/AuthConfirm.vue'),
      meta: {
        auth: false,
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        auth: false,
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
