import axios from 'axios'
import { eventBus } from '../../main'
import router from '../../router'

const localAxios = axios.create({
  baseURL: process.env.VUE_APP_HTTP || '',
})

function initialState() {
  return {
    portfolioStats: {},
    portfolioPairs: [],

    countPortfolio: {
      count_pairs: 0,
      count_tickers: 0,
    },
    countPortfolioSubscribed: {
      count_pairs: 0,
      count_tickers: 0,
    },

    highlightedPair: {
      x: undefined,
      y: undefined,
    },

    dayGain: 0,

    responses: {
      addToPortfolio: false,
      getPortfolio: false,
      changePortfolioNote: false,
      deletePortfolio: false,
      resetPortfolio: false,
    },
  }
}

let callAddPortfolio
let callGetPortfolio
let callGetCountPortfolio
let callGetCountPortfolioSubscribed
let callChangePortfolioNote
let callDeletePortfolio
let callResetPortfolio

export default {
  namespaced: true,
  state: initialState,
  mutations: {

    RESET_STATE(state) {
      const initial = initialState()
      Object.keys(initial).forEach(key => {
        state[key] = initial[key]
      })
    },

    SAVE_RESPONSE_STATE(state, { type, currState }) {
      state.responses[type] = currState
    },

    SAVE_PORTFOLIO(state, data) {
      // copy in local varaible
      const income = {}
      Object.assign(income, data)

      // save only .pairs Array as Portfolio pairs
      state.portfolioPairs = income.pairs

      // delete .pairs Array
      delete income.pairs

      // save remaining data as Portfolio stats
      state.portfolioStats = income
    },

    HIGHLIGHT_PORTFOLIO(state, data) {
      state.highlightedPair = data
      setTimeout(() => { state.highlightedPair = { x: undefined, y: undefined } }, 2300)
    },

    SAVE_COUNT_PORTFOLIO(state, data) {
      state.countPortfolio = data
    },

    SAVE_COUNT_PORTFOLIO_SUBSCRIBED(state, data) {
      state.countPortfolioSubscribed = data
    },

    DELETE_PORTFOLIO(state, data) {
      // this is for Front-end purpose only
      // to instantly remove DOM element before
      // request "getPortfolio" was made
      // and new data arrived
      for (let n = 0; n < data.length; n++) {
        for (let i = 0; i < state.portfolioPairs.length; i++) {
          if (state.portfolioPairs[i].x_id === data[n].x && state.portfolioPairs[i].y_id === data[n].y) {
            state.portfolioPairs.splice(i, 1)
          }
        }
      }
    },

    SAVE_DAY_GAIN(state, data) {
      state.dayGain = data
    },

  },
  actions: {

    addToPortfolio({ commit, dispatch }, { params, aToken }) {
      if (callAddPortfolio) {
        callAddPortfolio.cancel()
      }
      callAddPortfolio = axios.CancelToken.source()

      commit('SAVE_RESPONSE_STATE', {
        type: 'addToPortfolio',
        currState: true,
      })

      const request = new Promise((resolve, reject) => {
        localAxios
          .post(
            process.env.VUE_APP_PORTFOLIO,
            params,
            {
              headers: {
                Authorization: `Bearer ${aToken}`,
              },
              cancelToken: callAddPortfolio.token,
            },
          )

          .then(res => {
            const data = res.data

            if (data.success) {
              resolve('success')
            } else {
              reject()
              eventBus.$emit('showToast/Portfolio', {
                title: data.result.message,
                variant: 'warning',
                icon: 'AlertTriangleIcon',
              })
            }

            commit('SAVE_RESPONSE_STATE', {
              type: 'addToPortfolio',
              currState: false,
            })
          })

          .catch(err => {
            console.error(err)

            if (!axios.isCancel(err)) {
              eventBus.$emit('showToast/Portfolio', {
                title: 'Unexpected Error',
                variant: 'danger',
                icon: 'AlertOctagonIcon',
              })
              commit('SAVE_RESPONSE_STATE', {
                type: 'addToPortfolio',
                currState: false,
              })
            } else {
              commit('SAVE_RESPONSE_STATE', {
                type: 'addToPortfolio',
                currState: true,
              })
            }

            reject(err)
          })
      })

      request
        .then(result => {
          if (result === 'success') {
            dispatch('getPortfolio', {
              aToken: aToken,
              changeRoute: true,
              highlight: {
                x: params.x_id,
                y: params.y_id,
              },
            })
          }
        })

        .catch(err => {
          console.error(err)
        })
    },

    getPortfolio({ commit }, { aToken, changeRoute, highlight }) {
      if (callGetPortfolio) {
        callGetPortfolio.cancel()
      }
      callGetPortfolio = axios.CancelToken.source()

      commit('SAVE_RESPONSE_STATE', {
        type: 'getPortfolio',
        currState: true,
      })

      const request = new Promise((resolve, reject) => {
        localAxios
          .get(
            process.env.VUE_APP_PORTFOLIO,
            {
              headers: {
                Authorization: `Bearer ${aToken}`,
              },
              cancelToken: callGetPortfolio.token,
            },
          )

          .then(res => {
            const data = res.data

            if (data.success) {
              commit('SAVE_PORTFOLIO', data.result)
              
              resolve('success')
            } else {
              eventBus.$emit('showToast/Portfolio', {
                title: data.result.message,
                variant: 'warning',
                icon: 'AlertTriangleIcon',
              })

              reject()
            }
          })

          .catch(err => {
            console.error(err)

            eventBus.$emit('showToast/Portfolio', {
              title: 'Unexpected Error',
              variant: 'danger',
              icon: 'AlertOctagonIcon',
            })

            reject(err)
          })
      })

      request
        .then(result => {
          if (result === 'success') {
            // If user just added new pair to Portfolio
            // highlight it on portfolio page
            if (highlight) {
              commit('HIGHLIGHT_PORTFOLIO', highlight)
            }
            // If user clicked on button on Details page, reroute him
            // otherwise, if he went from main menu, for example, don't do that
            if (changeRoute) {
              router.push({ name: 'portfolio' }).catch(err => {
                console.error(err)
              })
            }
          }
        })

        .catch(err => {
          console.error(err)

          if (!axios.isCancel(err)) {
            eventBus.$emit('showToast/Portfolio', {
              title: 'Unexpected Error',
              variant: 'danger',
              icon: 'AlertOctagonIcon',
            })
          }
        })

        .finally(() => {
          commit('SAVE_RESPONSE_STATE', {
            type: 'getPortfolio',
            currState: false,
          })
        })
    },

    getCountPortfolio({ commit }, aToken) {
      if (callGetCountPortfolio) {
        callGetCountPortfolio.cancel()
      }
      callGetCountPortfolio = axios.CancelToken.source()

      localAxios
        .get(
          process.env.VUE_APP_COUNT_PORTFOLIO,
          {
            headers: {
              Authorization: `Bearer ${aToken}`,
            },
            cancelToken: callGetCountPortfolio.token,
          },
        )

        .then(res => {
          const data = res.data

          if (data.success) {
            commit('SAVE_COUNT_PORTFOLIO', data.result)
          } else {
            eventBus.$emit('showToast/Portfolio', {
              title: data.result.message,
              variant: 'warning',
              icon: 'AlertTriangleIcon',
            })
          }
        })

        .catch(err => {
          console.error(err)

          eventBus.$emit('showToast/Portfolio', {
            title: 'Unexpected Error',
            variant: 'danger',
            icon: 'AlertOctagonIcon',
          })
        })
    },

    getCountPortfolioSubscribed({ commit }, aToken) {
      if (callGetCountPortfolioSubscribed) {
        callGetCountPortfolioSubscribed.cancel()
      }
      callGetCountPortfolioSubscribed = axios.CancelToken.source()

      localAxios
        .get(
          process.env.VUE_APP_COUNT_PORTFOLIO_SUBSCRIBED,
          {
            headers: {
              Authorization: `Bearer ${aToken}`,
            },
            cancelToken: callGetCountPortfolioSubscribed.token,
          },
        )

        .then(res => {
          const data = res.data

          if (data.success) {
            commit('SAVE_COUNT_PORTFOLIO_SUBSCRIBED', data.result)
          } else {
            eventBus.$emit('showToast/Portfolio', {
              title: data.result.message,
              variant: 'warning',
              icon: 'AlertTriangleIcon',
            })
          }
        })

        .catch(err => {
          console.error(err)

          eventBus.$emit('showToast/Portfolio', {
            title: 'Unexpected Error',
            variant: 'danger',
            icon: 'AlertOctagonIcon',
          })
        })
    },

    changePortfolioNote({ commit }, { params, aToken }) {
      if (callChangePortfolioNote) {
        callChangePortfolioNote.cancel()
      }
      callChangePortfolioNote = axios.CancelToken.source()

      commit('SAVE_RESPONSE_STATE', {
        type: 'changePortfolioNote',
        currState: true,
      })

      const highlight = {
        x: params.x_id,
        y: params.y_id,
      }
      const payload = {}
      Object.assign(payload, params)

      Object.keys(payload).forEach(key => {
        key !== 'portfolio_id' && key !== 'note' ? delete payload[key] : false
      })

      localAxios
        .patch(
          process.env.VUE_APP_PORTFOLIO_NOTE,
          payload,
          {
            headers: {
              Authorization: `Bearer ${aToken}`,
            },
            cancelToken: callChangePortfolioNote.token,
          },
        )

        .then(res => {
          const data = res.data

          eventBus.$emit('showToast/Portfolio', {
            title: data.result.message,
            variant: data.success ? 'success' : 'warning',
            icon: data.success ? 'CheckIcon' : 'AlertTriangleIcon',
          })

          commit('SAVE_RESPONSE_STATE', {
            type: 'changePortfolioNote',
            currState: false,
          })

          commit('HIGHLIGHT_PORTFOLIO', highlight)
        })

        .catch(err => {
          console.error(err)

          if (!axios.isCancel(err)) {
            eventBus.$emit('showToast/Portfolio', {
              title: 'Unexpected Error',
              variant: 'danger',
              icon: 'AlertOctagonIcon',
            })
            commit('SAVE_RESPONSE_STATE', {
              type: 'changePortfolioNote',
              currState: false,
            })
          } else {
            commit('SAVE_RESPONSE_STATE', {
              type: 'changePortfolioNote',
              currState: true,
            })
          }
        })
    },

    deletePortfolio({ commit, dispatch }, { params, aToken }) {
      if (callDeletePortfolio) {
        callDeletePortfolio.cancel()
      }
      callDeletePortfolio = axios.CancelToken.source()

      commit('SAVE_RESPONSE_STATE', {
        type: 'deletePortfolio',
        currState: true,
      })

      commit('DELETE_PORTFOLIO', params)

      localAxios
        .delete(
          process.env.VUE_APP_PORTFOLIO,
          {
            headers: {
              Authorization: `Bearer ${aToken}`,
            },
            cancelToken: callDeletePortfolio.token,
            data: params,
          },
        )

        .then(res => {
          const data = res.data

          if (data.success) {
            eventBus.$emit('showToast/Portfolio', {
              title: 'Pair have been deleted from Portfolio',
              variant: 'success',
              icon: 'CheckIcon',
            })

            dispatch('getPortfolio', {
              aToken: aToken,
              changeRoute: false,
              highlight: null,
            })

            dispatch('getCountPortfolio', aToken)
            dispatch('getCountPortfolioSubscribed', aToken)
          } else {
            eventBus.$emit('showToast/Portfolio', {
              title: data.result.message,
              variant: 'warning',
              icon: 'AlertTriangleIcon',
            })
          }
        })

        .catch(err => {
          console.error(err)

          if (!axios.isCancel(err)) {
            eventBus.$emit('showToast/Portfolio', {
              title: 'Unexpected Error',
              variant: 'danger',
              icon: 'AlertOctagonIcon',
            })
          }
        })

        .finally(() => {
          commit('SAVE_RESPONSE_STATE', {
            type: 'deletePortfolio',
            currState: false,
          })
        })
    },

    resetPortfolio({ commit, dispatch }, aToken) {
      if (callResetPortfolio) {
        callResetPortfolio.cancel()
      }
      callResetPortfolio = axios.CancelToken.source()

      commit('SAVE_RESPONSE_STATE', {
        type: 'resetPortfolio',
        currState: true,
      })

      const request = new Promise((resolve, reject) => {
        localAxios
          .post(
            process.env.VUE_APP_PORTFOLIO_RESET,
            {},
            {
              headers: {
                Authorization: `Bearer ${aToken}`,
              },
              cancelToken: callResetPortfolio.token,
            },
          )

          .then(res => {
            const data = res.data

            if (data.success) {
              eventBus.$emit('showToast/Portfolio', {
                title: data.result.message,
                variant: 'success',
                icon: 'CheckIcon',
              })
              
              resolve('success')
            } else {
              eventBus.$emit('showToast/Portfolio', {
                title: data.result.message,
                variant: 'warning',
                icon: 'AlertTriangleIcon',
              })

              reject()
            }
          })

          .catch(err => {
            console.error(err)

            eventBus.$emit('showToast/Portfolio', {
              title: 'Unexpected Error',
              variant: 'danger',
              icon: 'AlertOctagonIcon',
            })

            reject(err)
          })
      })

      request
        .then(result => {
          if (result === 'success') {
            dispatch('getPortfolio', {
              aToken: aToken,
              changeRoute: false,
            })
          }
        })

        .catch(err => {
          console.error(err)

          if (!axios.isCancel(err)) {
            eventBus.$emit('showToast/Portfolio', {
              title: 'Unexpected Error',
              variant: 'danger',
              icon: 'AlertOctagonIcon',
            })
          }
        })

        .finally(() => {
          commit('SAVE_RESPONSE_STATE', {
            type: 'resetPortfolio',
            currState: false,
          })
        })
    },

    getDayGain({ commit }, aToken) {
      localAxios
        .get(
          process.env.VUE_APP_DAY_GAIN,
          {
            headers: {
              Authorization: `Bearer ${aToken}`,
            },
          },
        )

        .then(res => {
          const data = res.data

          if (data.success) {
            commit('SAVE_DAY_GAIN', data.result.day_gain_change)
          } else {
            eventBus.$emit('showToast', {
              title: res.response.message,
              variant: 'warning',
              icon: 'AlertTriangleIcon',
            })
          }
        })

        .catch(err => {
          console.error(err)

          eventBus.$emit('showToast', {
            title: 'Unexpected Error',
            variant: 'danger',
            icon: 'AlertOctagonIcon',
          })
        })
    },

  },
}
