<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]">
    <component :is="layout">
      <router-view />
    </component>

  </div>
</template>

<script>
// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import '@core/scss/vue/libs/vue-good-table.scss'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'

import { eventBus } from './main'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

  },
  data() {
    return {
      authTimer: null,
      subTimer: null,
    }
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {

    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },

    contentLayoutType() {
      return this.$store.state.config.layout.type
    },

    rToken() {
      return this.$store.state.auth.rToken
    },

    aToken() {
      return this.$store.state.auth.aToken
    },

    isLoggedIn: {
      get: function () {
        return this.$store.state.auth.isLoggedIn
      },
      set: function (value) {
        this.$store.state.auth.isLoggedIn = value
      },
    },

    routerAuth() {
      return this.$route.meta.auth
    },

    params() {
      return this.$store.state.pairs.params
    },

    userSubscription() {
      return this.$store.state.user.subscription
    },

  },
  watch: {
    isLoggedIn(newval, oldval) {
      const that = this

      if (newval === oldval && newval === false) {
        if (this.routerAuth) {
          this.$router.push({ name: 'login' }).catch(err => {
            console.error(err)
          })
        }
      } else if (newval === oldval && newval === true) {
        return false
      } else if (newval === true) {
        that.authTimer = setInterval(() => {
          that.authExpires().catch(error => {
            console.error(error)
          })
        }, 5000)
      } else if (newval === false) {
        if (this.authTimer) {
          clearInterval(this.authTimer)
        }
        if (this.routerAuth) {
          this.$router.push({ name: 'login' }).catch(err => {
            console.error(err)
          })
        }
      }
    },

    userSubscription(newVal, oldVal) {
      if (newVal.status !== oldVal.status
      && newVal.status !== undefined
      && oldVal.status !== undefined) {
        this.clearInteval()
        this.$store.dispatch('auth/refreshTokens')

        this.showToast({
          title: 'Subscription status changed',
          variant: newVal.status === 'active' ? 'success' : 'danger',
          icon: newVal.status === 'active' ? 'CheckIcon' : 'AlertOctagonIcon',
        })
      }
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  created() {
    this.getUser()
    this.getOutdatedPairs()
  },
  mounted() {
    eventBus.$on('getUser', () => { this.getUser() })
    eventBus.$on('getPairs', () => { this.getDefaultPairs() })
    eventBus.$on('getOutdatedPairs', () => { this.getOutdatedPairs() })
    eventBus.$on('showToast', data => { this.showToast(data) })

    document.addEventListener('visibilitychange', () => {
      const that = this

      if (!document.hidden) {
        that.authExpires().catch(error => {
          console.error(error)
        })
      }
    })
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('breakpoints/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('breakpoints/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
    }
  },
  methods: {
    authExpires() {
      if (!this.isLoggedIn) {
        clearInterval(this.authTimer)
      }

      return new Promise((resolve, reject) => {
        const that = this

        let expiresR = 0
        let expiresA = 0

        function manualLogout() {
          clearInterval(that.authTimer)

          that.$store.commit('auth/SAVE_USER', {
            nickName: null,
            email: null,
            userRole: null,
            uuid: null,
            sub: null,
          })

          that.$store.commit('auth/SAVE_LOGIN_STATE', false)

          // In case of error, Arbinox version was: `localStorage.removeItem("")`
          that.$store.commit('auth/SAVE_TOKENS', {
            aToken: '.',
            rToken: '.',
          })

          if (that.routerAuth) {
            that.$router.push({ name: 'login' }).catch(err => {
              console.error(err)
            })
          }

          this.$store.commit('config/CHANGE_TOUR_STATE', false)
          document.body.classList.remove('v-tour--active')

          // In case of error, Arbinox version was: `return reject({ message: "" })`
          return reject(new Error('Authorization failed!'))
        }

        if (!localStorage.getItem('tokenPending')) {
          if (this.aToken === '.' || this.rToken === '.') {
            if (that.routerAuth) {
              that.$router.push({ name: 'login' }).catch(err => {
                console.error(err)
              })
            }

            return reject(new Error('Unauthorized!'))
          } else {
            if (localStorage.getItem('refreshToken') && that.$store.state.auth.rToken !== localStorage.getItem('refreshToken')) {
              that.$store.state.auth.rToken = localStorage.getItem('refreshToken')
            }
            if (localStorage.getItem('accessToken') && that.$store.state.auth.aToken !== localStorage.getItem('accessToken')) {
              that.$store.state.auth.aToken = localStorage.getItem('accessToken')
            }

            const accessTokenExpire = JSON.parse(
              atob((localStorage.getItem('accessToken') || '.').split('.')[1]) || '{}',
            )
            const refreshTokenExpire = JSON.parse(
              atob((localStorage.getItem('refreshToken') || '.').split('.')[1]) || '{}',
            )

            expiresR = parseInt(refreshTokenExpire.exp, 10)
            expiresA = parseInt(accessTokenExpire.exp, 10)

            if (Number.isNaN(expiresR) || Number.isNaN(expiresA)) {
              this.$store.dispatch('auth/signOut')

              return reject(new Error('Wrong tokens!'))
            } else {
              if ((new Date()).getTime() < (new Date(expiresR * 1000)).getTime()) {
                that.$store.commit('auth/SAVE_LOGIN_STATE', true)
              }

              if ((new Date()).getTime() >= (new Date((expiresA - 5) * 1000)).getTime() && (new Date()).getTime() < (new Date(expiresR * 1000)).getTime()) {
                this.$store
                  .dispatch('auth/refreshTokens')
                  .then(result => {
                    return resolve(result.aToken)
                  })
                  .catch(manualLogout)
              } else if ((new Date()).getTime() <= (new Date(expiresA * 1000)).getTime()) {
                if (localStorage.getItem('refreshToken') && that.$store.state.auth.rToken !== localStorage.getItem('refreshToken')) {
                  that.$store.state.auth.rToken = localStorage.getItem('refreshToken')
                }
                if (localStorage.getItem('accessToken') && that.$store.state.auth.aToken !== localStorage.getItem('accessToken')) {
                  that.$store.state.auth.aToken = localStorage.getItem('accessToken')
                }
                return resolve(this.aToken)
              } else {
                manualLogout()
              }
            }
          }
        } else {
          setTimeout(() => {
            if (localStorage.getItem('refreshToken') && that.$store.state.auth.rToken !== localStorage.getItem('refreshToken')) {
              that.$store.state.auth.rToken = localStorage.getItem('refreshToken')
            }
            if (localStorage.getItem('accessToken') && that.$store.state.auth.aToken !== localStorage.getItem('accessToken')) {
              that.$store.state.auth.aToken = localStorage.getItem('accessToken')
            }
            return resolve(localStorage.getItem('accessToken'))
          }, 2000)
        }
      })
    },

    showToast(data) {
      this.$toast({
        component: ToastificationContent,
        props: data,
      })
    },

    getUser() {
      this.$root.$children[0]
        .authExpires()
        .then(result => {
          this.$store.dispatch('user/getProfile', result)
          this.$store.dispatch('user/getSubscription', result)
          this.$store.dispatch('watchlist/getNotifications', result)
          this.$store.dispatch('portfolio/getDayGain', result)
        })
    },

    getDefaultPairs() {
      this.params.page = 1

      this.$root.$children[0]
        .authExpires()
        .then(result => {
          this.$store.dispatch('pairs/getCountPairs', {
            params: this.params,
            aToken: result,
          })
          this.$store.dispatch('pairs/getTotalPairs', result)
          this.$store.dispatch('pairs/getPairs', {
            params: this.params,
            aToken: result,
          })
        })
    },

    getOutdatedPairs() {
      this.$root.$children[0]
        .authExpires()
        .then(result => {
          this.$store.dispatch('pairs/getOutdatedPairs', result)
        })
    },

    repeatPlan() {
      this.subTimer = setInterval(() => {
        this.getPlan()
      }, 3000)
    },

    getPlan() {
      this.$root.$children[0]
        .authExpires()
        .then(result => {
          this.$store.dispatch('user/getSubscription', result)
        })
    },

    clearInteval() {
      clearInterval(this.subTimer)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/tour.scss';

.grecaptcha-badge {
  display: none !important;
}
</style>
