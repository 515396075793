(function (root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['exports', 'echarts'], factory)
  } else if (typeof exports === 'object' && typeof exports.nodeName !== 'string') {
    // CommonJS
    factory(exports, require('echarts'))
  } else {
    // Browser globals
    factory({}, root.echarts)
  }
}(this, (exports, echarts) => {
  const log = function (msg) {
    if (typeof console !== 'undefined') {
      console && console.error && console.error(msg)
    }
  }
  if (!echarts) {
    log('ECharts is not Loaded')
    return
  }

  // Dublicated from @core/scss/base/bootstrap-extended/_varaibles.scss
  const pallete = {
    '$theme-light-body-bg': '#f8f8f8',
    '$theme-light-body-color': '#252429',
    '$theme-light-body-color-muted': '#B9B9C3',
    '$theme-light-body-color-disabled': '#EFEFEF',
    '$theme-light-heading-color': '#5E5873',
    '$theme-light-dark-color': '#22292f',
    '$theme-light-darken-color': '#4b4b4b',
    '$theme-light-border-color': '#d8d6de',

    '$theme-white-color': '#FFFFFF',
    '$gray-50': '#f6f6f6',
    '$gray-100': '#babfc7',
    '$gray-200': '#ededed',
    '$gray-300': '#dae1e7',
    '$gray-400': '#636363',
    '$gray-500': '#adb5bd',
    '$gray-600': '#b8c2cc',
    '$gray-700': '#4e5154',
    '$gray-800': '#1e1e1e',
    '$gray-900': '#2a2e30',
  }

  const theme = {
    title: {
      textStyle: {
        color: pallete['$theme-light-heading-color'],
      },
      subtextStyle: {
        color: pallete['$theme-light-dark-color'],
      },
    },
    valueAxis: {
      axisLine: {
        onZero: true,
        lineStyle: {
          color: pallete['$theme-light-border-color'],
          width: 1,
        },
      },
      axisLabel: {
        fontSize: 10,
        rotate: 45,
      },
      splitLine: {
        lineStyle: {
          color: pallete['$theme-light-border-color'],
        },
      },
      nameTextStyle: {
        color: pallete['$theme-light-body-color'],
      },
    },
    categoryAxis: {
      boundaryGap: false,
      axisLine: {
        onZero: true,
        lineStyle: {
          color: pallete['$theme-light-border-color'],
          width: 1,
        },
      },
      nameTextStyle: {
        color: pallete['$theme-light-body-color'],
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: pallete['$theme-light-border-color'],
          type: 'dashed',
          opacity: 0.8,
        },
      },
      axisLabel: {
        margin: 10,
        rotate: 45,
        fontSize: 10,
        showMinLabel: false,
      },
    },
    line: {
      hoverAnimation: false,
      symbol: 'circle',
      symbolSize: 6,
      smooth: true,
      showSymbol: false,
      lineStyle: {
        width: 1.5,
      },
      markLine: {
        lineStyle: {
          color: pallete['$gray-700'],
          type: 'dashed',
        },
        symbol: 'none',
        silent: true,
      },
    },
    pie: {
      radius: ['60%', '90%'],
      avoidLabelOverlap: false,
      itemStyle: {
        borderColor: '#F2F2F2',
        borderWidth: 2,
      },
      label: {
        show: false,
        position: 'center',
      },
      labelLine: {
        show: false,
      },
      emphasis: {
        label: {
          show: false,
        },
      },
    },
    tooltip: {
      axisPointer: {
        animation: false,
      },
      backgroundColor: pallete['$theme-white-color'],
      borderColor: pallete['$theme-light-border-color'],
      extraCssText: 'box-shadow: 0 0 10px 0 rgba(34, 41, 47, 0.1);',
      borderWidth: 1,
      padding: 10,
      textStyle: {
        color: pallete['$theme-light-heading-color'],
      },
    },
    legend: {
      show: false,
    },
    toolbox: {
      show: false,
    },
    axisPointer: {
      lineStyle: {
        color: pallete['$theme-light-border-color'],
        width: 1,
      },
    },
    dataZoom: {
      borderColor: pallete['$theme-light-border-color'],
      backgroundColor: pallete['$theme-light-white-color'],
      fillerColor: 'rgba(0,0,0,0.09)',
      dataBackground: {
        lineStyle: {
          color: pallete['$theme-light-border-color'],
        },
        areaStyle: {
          color: pallete['$theme-light-darken-color'],
        },
      },
      selectedDataBackground: {
        lineStyle: {
          color: pallete['$theme-light-border-color'],
        },
        areaStyle: {
          color: pallete['$theme-light-darken-color'],
        },
      },
      textStyle: {
        color: pallete['$theme-light-body-color'],
        fontFamily: '"Rubik", Helvetica, Arial, serif',
        fontSize: 10,
      },
    },
    grid: {
      show: true,
      backgroundColor: pallete['$theme-light-body-bg'],
      borderWidth: 0,
    },
    textStyle: {
      color: pallete['$theme-light-body-color'],
      fontFamily: '"Rubik", Helvetica, Arial, serif',
    },
    animation: false,
    animationDuration: 500,
    animationEasing: 'linear',
  }
  echarts.registerTheme('light', theme)
}))
