import Vue from 'vue'
import axios from 'axios'
import { eventBus } from '../../main'
import router from '../../router'

const localAxios = axios.create({
  baseURL: process.env.VUE_APP_HTTP || '',
})

function initialState() {
  return {
    watchlist: [],
    countWatchlist: 0,
    countWatchlistSubscribed: 0,

    klines1y: {},

    highlightedPair: {
      x: undefined,
      y: undefined,
    },

    selectedPair: {},

    telegramStatus: {},

    notifications: [],

    notificationsDropdown: false,

    responses: {
      addToWatchlist: false,
      getWatchlist: false,
      deleteWatchlist: false,
      connectTelegram: false,
      disconnectTelegram: false,
      savePairAlert: false,
      klines1y: false,
      setTelegramAlert: false,
      deleteTelegramAlert: false,
    },
  }
}

let callAddWatchlist
let callGetWatchlist
let callGetCountWatchlist
let callGetCountWatchlistSubscribed
let callDeleteWatchlist
let callTelegramStatus
let callConnectTelegram
let callDisonnectTelegram
let callSavePairAlert
let callGetWatchlistPair
let callKlines1y
let callSetTelegramAlert
let callDeleteTelegramAlert

export default {
  namespaced: true,
  state: initialState,
  mutations: {

    RESET_STATE(state) {
      const initial = initialState()
      Object.keys(initial).forEach(key => {
        state[key] = initial[key]
      })
    },

    SAVE_RESPONSE_STATE(state, { type, currState }) {
      state.responses[type] = currState
    },

    SAVE_WATCHLIST(state, data) {
      for (let i = 0; i < data.length; i++) {
        // Calculate OLS
        data[i].ols = ((data[i].y_price - data[i].coef * data[i].x_price) / (data[i].coef * data[i].x_price)) * 100
        // Define sector
        data[i].pairSector = data[i].x_sector === data[i].y_sector ? data[i].x_sector : 'Cross-sector'
      }

      state.watchlist = data
    },

    SAVE_COUNT_WATCHLIST(state, data) {
      state.countWatchlist = data
    },

    SAVE_COUNT_WATCHLIST_SUBSCRIBED(state, data) {
      state.countWatchlistSubscribed = data
    },

    SAVE_WATCHLIST_PAIR(state, data) {
      const target = state.watchlist.filter(pair => pair.x_id === data.x_id && pair.y_id === data.y_id)[0]

      Object.keys(data).forEach(key => {
        Vue.set(target, key, data[key])
      })

      eventBus.$emit('filterWatchlist')
    },

    SAVE_WATCHLIST_PAIR_SETTINGS(state, data) {
      state.selectedPair = data
    },

    DELETE_WATCHLIST(state, data) {
      // this is for Front-end purpose only
      // to instantly remove DOM element before
      // request "getWatchlist" was made
      // and new data arrived
      for (let n = 0; n < data.length; n++) {
        for (let i = 0; i < state.watchlist.length; i++) {
          if (state.watchlist[i].x_id === data[n].x && state.watchlist[i].y_id === data[n].y) {
            state.watchlist.splice(i, 1)
          }
        }
      }
    },

    HIGHLIGHT_WATCHLIST(state, data) {
      state.highlightedPair = data
      setTimeout(() => { state.highlightedPair = { x: undefined, y: undefined } }, 2300)
    },

    SAVE_TELEGRAM_STATUS(state, data) {
      state.telegramStatus = data
    },

    SAVE_KILNES(state, data) {
      state.klines1y = data
    },

    SAVE_NOTIFICATIONS(state, data) {
      state.notifications = data
    },

    DELETE_NOTIFICATION(state, data) {
      const target = state.notifications.filter(alert => alert.x === data[0].x && alert.y === data[0].y)[0]
      state.notifications.splice(state.notifications.indexOf(target), 1)
    },

    DELETE_ALL_NOTIFICATIONS(state) {
      state.notifications = []
    },

    CHANGE_NOTIFICATIONS_DROPDOWN_STATE(state, data) {
      state.notificationsDropdown = data
    },

  },
  actions: {

    addToWatchlist({ commit, dispatch }, { params, changeRoute, aToken }) {
      if (callAddWatchlist) {
        callAddWatchlist.cancel()
      }
      callAddWatchlist = axios.CancelToken.source()

      commit('SAVE_RESPONSE_STATE', {
        type: 'addToWatchlist',
        currState: true,
      })

      const request = new Promise((resolve, reject) => {
        localAxios
          .post(
            process.env.VUE_APP_WATCHLIST,
            params,
            {
              headers: {
                Authorization: `Bearer ${aToken}`,
              },
              cancelToken: callAddWatchlist.token,
            },
          )

          .then(res => {
            const data = res.data

            if (data.success) {
              resolve('success')
            } else {
              reject()
              eventBus.$emit('showToast/Watchlist', {
                title: data.result.message,
                variant: 'warning',
                icon: 'AlertTriangleIcon',
              })
            }

            commit('SAVE_RESPONSE_STATE', {
              type: 'addToWatchlist',
              currState: false,
            })
          })

          .catch(err => {
            console.error(err)

            if (!axios.isCancel(err)) {
              eventBus.$emit('showToast/Watchlist', {
                title: 'Unexpected Error',
                variant: 'danger',
                icon: 'AlertOctagonIcon',
              })
              commit('SAVE_RESPONSE_STATE', {
                type: 'addToWatchlist',
                currState: false,
              })
            } else {
              commit('SAVE_RESPONSE_STATE', {
                type: 'addToWatchlist',
                currState: true,
              })
            }

            reject(err)
          })
      })

      request
        .then(result => {
          if (result === 'success') {
            dispatch('getWatchlist', {
              aToken: aToken,
              changeRoute: changeRoute,
              highlight: params,
            })
          }
        })

        .catch(err => {
          console.error(err)
        })
    },

    getWatchlist({ commit }, {
      aToken, changeRoute, highlight, limit,
    }) {
      if (callGetWatchlist) {
        callGetWatchlist.cancel()
      }
      callGetWatchlist = axios.CancelToken.source()

      commit('SAVE_RESPONSE_STATE', {
        type: 'getWatchlist',
        currState: true,
      })

      const requestUrl = limit ? `${process.env.VUE_APP_WATCHLIST}?count=${limit}&light=true` : process.env.VUE_APP_WATCHLIST

      const request = new Promise((resolve, reject) => {
        localAxios
          .get(
            requestUrl,
            {
              headers: {
                Authorization: `Bearer ${aToken}`,
              },
              cancelToken: callGetWatchlist.token,
            },
          )

          .then(res => {
            const data = res.data

            if (data.success) {
              commit('SAVE_WATCHLIST', data.result)

              resolve('success')
            } else {
              eventBus.$emit('showToast/Watchlist', {
                title: data.result.message,
                variant: 'warning',
                icon: 'AlertTriangleIcon',
              })

              reject()
            }
          })

          .catch(err => {
            console.error(err)

            eventBus.$emit('showToast/Watchlist', {
              title: 'Unexpected Error',
              variant: 'danger',
              icon: 'AlertOctagonIcon',
            })

            reject(err)
          })
      })

      request
        .then(result => {
          if (result === 'success') {
            // If user just added new pair to Watchlist
            // highlight it on watchlist page
            if (highlight) {
              commit('HIGHLIGHT_WATCHLIST', highlight)
            }
            // If user clicked on button on Details page, reroute him
            // otherwise, if he went from main menu, for example, don't do that
            if (changeRoute) {
              router.push({ name: 'watchlist' }).catch(err => {
                console.error(err)
              })
            }
          }
        })

        .catch(err => {
          console.error(err)

          if (!axios.isCancel(err)) {
            eventBus.$emit('showToast/Watchlist', {
              title: 'Unexpected Error',
              variant: 'danger',
              icon: 'AlertOctagonIcon',
            })
          }
        })

        .finally(() => {
          commit('SAVE_RESPONSE_STATE', {
            type: 'getWatchlist',
            currState: false,
          })
        })
    },

    getCountWatchlist({ commit }, aToken) {
      if (callGetCountWatchlist) {
        callGetCountWatchlist.cancel()
      }
      callGetCountWatchlist = axios.CancelToken.source()

      localAxios
        .get(
          process.env.VUE_APP_COUNT_WATCHLIST,
          {
            headers: {
              Authorization: `Bearer ${aToken}`,
            },
            cancelToken: callGetCountWatchlist.token,
          },
        )

        .then(res => {
          const data = res.data

          if (data.success) {
            commit('SAVE_COUNT_WATCHLIST', data.result.count)
          } else {
            eventBus.$emit('showToast/Watchlist', {
              title: data.result.message,
              variant: 'warning',
              icon: 'AlertTriangleIcon',
            })
          }
        })

        .catch(err => {
          console.error(err)

          eventBus.$emit('showToast/Watchlist', {
            title: 'Unexpected Error',
            variant: 'danger',
            icon: 'AlertOctagonIcon',
          })
        })
    },

    getCountWatchlistSubscribed({ commit }, aToken) {
      if (callGetCountWatchlistSubscribed) {
        callGetCountWatchlistSubscribed.cancel()
      }
      callGetCountWatchlistSubscribed = axios.CancelToken.source()

      localAxios
        .get(
          process.env.VUE_APP_COUNT_WATCHLIST_SUBSCRIBED,
          {
            headers: {
              Authorization: `Bearer ${aToken}`,
            },
            cancelToken: callGetCountWatchlistSubscribed.token,
          },
        )

        .then(res => {
          const data = res.data

          if (data.success) {
            commit('SAVE_COUNT_WATCHLIST_SUBSCRIBED', data.result.count)
          } else {
            eventBus.$emit('showToast/Watchlist', {
              title: data.result.message,
              variant: 'warning',
              icon: 'AlertTriangleIcon',
            })
          }
        })

        .catch(err => {
          console.error(err)

          eventBus.$emit('showToast/Watchlist', {
            title: 'Unexpected Error',
            variant: 'danger',
            icon: 'AlertOctagonIcon',
          })
        })
    },

    getWatchlistPair({ commit }, { params, pairSettings, aToken }) {
      if (callGetWatchlistPair) {
        callGetWatchlistPair.cancel()
      }
      callGetWatchlistPair = axios.CancelToken.source()

      localAxios
        .post(
          process.env.VUE_APP_WATCHLIST_PAIR,
          params,
          {
            headers: {
              Authorization: `Bearer ${aToken}`,
            },
            cancelToken: callGetWatchlistPair.token,
          },
        )

        .then(res => {
          const data = res.data

          if (data.success) {
            commit('SAVE_WATCHLIST_PAIR', data.result)
            commit('HIGHLIGHT_WATCHLIST', params)
            if (pairSettings) {
              commit('SAVE_WATCHLIST_PAIR_SETTINGS', pairSettings)
            }
          } else {
            eventBus.$emit('showToast/Watchlist', {
              title: data.result.message,
              variant: 'warning',
              icon: 'AlertTriangleIcon',
            })
          }
        })

        .catch(err => {
          console.error(err)

          eventBus.$emit('showToast/Watchlist', {
            title: 'Unexpected Error',
            variant: 'danger',
            icon: 'AlertOctagonIcon',
          })
        })
    },

    deleteWatchlist({ commit, dispatch }, { params, aToken }) {
      if (callDeleteWatchlist) {
        callDeleteWatchlist.cancel()
      }
      callDeleteWatchlist = axios.CancelToken.source()

      commit('SAVE_RESPONSE_STATE', {
        type: 'deleteWatchlist',
        currState: true,
      })

      commit('DELETE_WATCHLIST', params)

      localAxios
        .delete(
          process.env.VUE_APP_WATCHLIST,
          {
            headers: {
              Authorization: `Bearer ${aToken}`,
            },
            cancelToken: callDeleteWatchlist.token,
            data: params,
          },
        )

        .then(res => {
          const data = res.data

          if (data.success) {
            eventBus.$emit('showToast/Watchlist', {
              title: params.length > 1 ? 'Pairs have been deleted from Watchlist' : 'Pair have been deleted from Watchlist',
              variant: 'success',
              icon: 'CheckIcon',
            })

            dispatch('getWatchlist', {
              aToken: aToken,
              changeRoute: false,
              highlight: null,
            })

            dispatch('getCountWatchlist', aToken)
            dispatch('getCountWatchlistSubscribed', aToken)
          } else {
            eventBus.$emit('showToast/Watchlist', {
              title: data.result.message,
              variant: 'warning',
              icon: 'AlertTriangleIcon',
            })
          }
        })

        .catch(err => {
          console.error(err)

          if (!axios.isCancel(err)) {
            eventBus.$emit('showToast/Watchlist', {
              title: 'Unexpected Error',
              variant: 'danger',
              icon: 'AlertOctagonIcon',
            })
          }
        })

        .finally(() => {
          commit('SAVE_RESPONSE_STATE', {
            type: 'deleteWatchlist',
            currState: false,
          })
        })
    },

    checkTelegramStatus({ commit }, aToken) {
      if (callTelegramStatus) {
        callTelegramStatus.cancel()
      }
      callTelegramStatus = axios.CancelToken.source()

      localAxios
        .get(
          process.env.VUE_APP_TELEGRAM_STATUS,
          {
            headers: {
              Authorization: `Bearer ${aToken}`,
            },
            cancelToken: callTelegramStatus.token,
          },
        )

        .then(res => {
          const data = res.data

          if (data.success) {
            commit('SAVE_TELEGRAM_STATUS', data.result)
          } else {
            eventBus.$emit('showToast/Watchlist', {
              title: data.result.message,
              variant: 'warning',
              icon: 'AlertTriangleIcon',
            })
          }
        })

        .catch(err => {
          console.error(err)

          if (!axios.isCancel(err)) {
            eventBus.$emit('showToast/Watchlist', {
              title: 'Unexpected Error',
              variant: 'danger',
              icon: 'AlertOctagonIcon',
            })
          }
        })
    },

    connectTelegram({ commit }, aToken) {
      if (callConnectTelegram) {
        callConnectTelegram.cancel()
      }
      callConnectTelegram = axios.CancelToken.source()

      commit('SAVE_RESPONSE_STATE', {
        type: 'connectTelegram',
        currState: true,
      })

      localAxios
        .post(
          process.env.VUE_APP_TELEGRAM,
          {},
          {
            headers: {
              Authorization: `Bearer ${aToken}`,
            },
            cancelToken: callConnectTelegram.token,
          },
        )

        .then(res => {
          const data = res.data

          if (data.success) {
            window.open(data.result.url, '_blank').focus()
          } else {
            eventBus.$emit('showToast/Watchlist', {
              title: data.result.message,
              variant: 'warning',
              icon: 'AlertTriangleIcon',
            })
          }

          commit('SAVE_RESPONSE_STATE', {
            type: 'connectTelegram',
            currState: false,
          })
        })

        .catch(err => {
          console.error(err)

          if (!axios.isCancel(err)) {
            eventBus.$emit('showToast/Watchlist', {
              title: 'Unexpected Error',
              variant: 'danger',
              icon: 'AlertOctagonIcon',
            })
            commit('SAVE_RESPONSE_STATE', {
              type: 'connectTelegram',
              currState: false,
            })
          } else {
            commit('SAVE_RESPONSE_STATE', {
              type: 'connectTelegram',
              currState: true,
            })
          }
        })
    },

    disconnectTelegram({ commit }, aToken) {
      if (callDisonnectTelegram) {
        callDisonnectTelegram.cancel()
      }
      callDisonnectTelegram = axios.CancelToken.source()

      commit('SAVE_RESPONSE_STATE', {
        type: 'disconnectTelegram',
        currState: true,
      })

      localAxios
        .delete(
          process.env.VUE_APP_TELEGRAM,
          {
            headers: {
              Authorization: `Bearer ${aToken}`,
            },
            cancelToken: callDisonnectTelegram.token,
          },
        )

        .then(res => {
          const data = res.data

          eventBus.$emit('showToast/Watchlist', {
            title: data.result.message,
            variant: data.success ? 'success' : 'warning',
            icon: data.success ? 'CheckIcon' : 'AlertTriangleIcon',
          })

          commit('SAVE_RESPONSE_STATE', {
            type: 'disconnectTelegram',
            currState: false,
          })
        })

        .catch(err => {
          console.error(err)

          if (!axios.isCancel(err)) {
            eventBus.$emit('showToast/Watchlist', {
              title: 'Unexpected Error',
              variant: 'danger',
              icon: 'AlertOctagonIcon',
            })
            commit('SAVE_RESPONSE_STATE', {
              type: 'disconnectTelegram',
              currState: true,
            })
          } else {
            commit('SAVE_RESPONSE_STATE', {
              type: 'disconnectTelegram',
              currState: true,
            })
          }
        })
    },

    savePairAlert({ commit, dispatch }, { params, pairSettings, aToken }) {
      if (callSavePairAlert) {
        callSavePairAlert.cancel()
      }
      callSavePairAlert = axios.CancelToken.source()

      commit('SAVE_RESPONSE_STATE', {
        type: 'savePairAlert',
        currState: true,
      })

      const request = new Promise((resolve, reject) => {
        localAxios
          .post(
            process.env.VUE_APP_WATCHLIST_ALERT,
            params,
            {
              headers: {
                Authorization: `Bearer ${aToken}`,
              },
              cancelToken: callSavePairAlert.token,
            },
          )

          .then(res => {
            const data = res.data

            if (data.success) {
              eventBus.$emit('showToast/Watchlist', {
                title: data.result.message,
                variant: 'success',
                icon: 'CheckIcon',
              })

              resolve('success')
            } else {
              eventBus.$emit('showToast/Watchlist', {
                title: data.result.message,
                variant: 'warning',
                icon: 'AlertTriangleIcon',
              })

              reject()
            }

            commit('SAVE_RESPONSE_STATE', {
              type: 'savePairAlert',
              currState: false,
            })
          })

          .catch(err => {
            console.error(err)

            if (!axios.isCancel(err)) {
              eventBus.$emit('showToast/Watchlist', {
                title: 'Unexpected Error',
                variant: 'danger',
                icon: 'AlertOctagonIcon',
              })
              commit('SAVE_RESPONSE_STATE', {
                type: 'savePairAlert',
                currState: false,
              })
            } else {
              commit('SAVE_RESPONSE_STATE', {
                type: 'savePairAlert',
                currState: true,
              })
            }

            reject(err)
          })
      })

      request
        .then(result => {
          if (result === 'success') {
            dispatch('getWatchlistPair', {
              params: {
                x: params.x,
                y: params.y,
              },
              pairSettings: pairSettings,
              aToken: aToken,
            })

            dispatch('getNotifications', aToken)

            eventBus.$emit('initChartMini')
          }
        })

        .catch(err => {
          console.error(err)
        })
    },

    async getKlines1Year({ commit }, { params, aToken }) {
      if (callKlines1y) {
        callKlines1y.cancel()
      }
      callKlines1y = axios.CancelToken.source()

      commit('SAVE_RESPONSE_STATE', {
        type: 'klines1y',
        currState: true,
      })

      const request = new Promise((resolve, reject) => {
        localAxios
          .post(
            process.env.VUE_APP_KLINES_1Y,
            params,
            {
              headers: {
                Authorization: `Bearer ${aToken}`,
              },
              cancelToken: callKlines1y.token,
            },
          )

          .then(res => {
            const data = res.data

            if (data.success) {
              resolve('success')
              commit('SAVE_KILNES', data.result)
            } else {
              eventBus.$emit('showToast/Watchlist', {
                title: data.result.message,
                variant: 'warning',
                icon: 'AlertTriangleIcon',
              })
              reject()
            }

            commit('SAVE_RESPONSE_STATE', {
              type: 'klines1y',
              currState: false,
            })
          })

          .catch(err => {
            console.error(err)

            if (!axios.isCancel(err)) {
              eventBus.$emit('showToast/Watchlist', {
                title: 'Unexpected Error',
                variant: 'danger',
                icon: 'AlertOctagonIcon',
              })
              commit('SAVE_RESPONSE_STATE', {
                type: 'klines1y',
                currState: false,
              })
            } else {
              commit('SAVE_RESPONSE_STATE', {
                type: 'klines1y',
                currState: true,
              })
            }

            reject(err)
          })
      })

      request
        .then(result => {
          if (result === 'success') {
            eventBus.$emit('openWatchlistSettings')
          }
        })

        .catch(err => {
          console.error(err)
        })
    },

    setTelegramAlert({ dispatch, commit }, { params, aToken }) {
      if (callSetTelegramAlert) {
        callSetTelegramAlert.cancel()
      }
      callSetTelegramAlert = axios.CancelToken.source()

      commit('SAVE_RESPONSE_STATE', {
        type: 'setTelegramAlert',
        currState: true,
      })

      const request = new Promise((resolve, reject) => {
        localAxios
          .post(
            process.env.VUE_APP_TELEGRAM_ALERT,
            params,
            {
              headers: {
                Authorization: `Bearer ${aToken}`,
              },
              cancelToken: callSetTelegramAlert.token,
            },
          )

          .then(res => {
            const data = res.data

            if (data.success) {
              eventBus.$emit('showToast/Watchlist', {
                title: data.result.message,
                variant: 'success',
                icon: 'CheckIcon',
              })

              resolve('success')
            } else {
              eventBus.$emit('showToast/Watchlist', {
                title: data.result.message,
                variant: 'warning',
                icon: 'AlertTriangleIcon',
              })

              reject()
            }

            commit('SAVE_RESPONSE_STATE', {
              type: 'setTelegramAlert',
              currState: false,
            })
          })

          .catch(err => {
            console.error(err)

            if (!axios.isCancel(err)) {
              eventBus.$emit('showToast/Watchlist', {
                title: 'Unexpected Error',
                variant: 'danger',
                icon: 'AlertOctagonIcon',
              })
              commit('SAVE_RESPONSE_STATE', {
                type: 'setTelegramAlert',
                currState: false,
              })
            } else {
              commit('SAVE_RESPONSE_STATE', {
                type: 'setTelegramAlert',
                currState: true,
              })
            }

            reject(err)
          })
      })

      request
        .then(result => {
          if (result === 'success') {
            dispatch('getWatchlistPair', {
              params: {
                x: params.x,
                y: params.y,
              },
              pairSettings: null,
              aToken: aToken,
            })
          }
        })

        .catch(err => {
          console.error(err)
        })
    },

    deleteTelegramAlert({ dispatch, commit }, { params, aToken }) {
      if (callDeleteTelegramAlert) {
        callDeleteTelegramAlert.cancel()
      }
      callDeleteTelegramAlert = axios.CancelToken.source()

      commit('SAVE_RESPONSE_STATE', {
        type: 'deleteTelegramAlert',
        currState: true,
      })

      const request = new Promise((resolve, reject) => {
        localAxios
          .delete(
            process.env.VUE_APP_TELEGRAM_ALERT,
            {
              headers: {
                Authorization: `Bearer ${aToken}`,
              },
              data: params,
              cancelToken: callDeleteTelegramAlert.token,
            },
          )

          .then(res => {
            const data = res.data

            if (data.success) {
              eventBus.$emit('showToast/Watchlist', {
                title: data.result.message,
                variant: 'success',
                icon: 'CheckIcon',
              })

              resolve('success')
            } else {
              eventBus.$emit('showToast/Watchlist', {
                title: data.result.message,
                variant: 'warning',
                icon: 'AlertTriangleIcon',
              })

              reject()
            }

            commit('SAVE_RESPONSE_STATE', {
              type: 'deleteTelegramAlert',
              currState: false,
            })
          })

          .catch(err => {
            console.error(err)

            if (!axios.isCancel(err)) {
              eventBus.$emit('showToast/Watchlist', {
                title: 'Unexpected Error',
                variant: 'danger',
                icon: 'AlertOctagonIcon',
              })
              commit('SAVE_RESPONSE_STATE', {
                type: 'deleteTelegramAlert',
                currState: false,
              })
            } else {
              commit('SAVE_RESPONSE_STATE', {
                type: 'deleteTelegramAlert',
                currState: true,
              })
            }

            reject(err)
          })
      })

      request
        .then(result => {
          if (result === 'success') {
            dispatch('getWatchlistPair', {
              params: {
                x: params.x,
                y: params.y,
              },
              pairSettings: null,
              aToken: aToken,
            })
          }
        })

        .catch(err => {
          console.error(err)
        })
    },

    getNotifications({ commit }, aToken) {
      localAxios
        .get(
          process.env.VUE_APP_NOTIFICATIONS,
          {
            headers: {
              Authorization: `Bearer ${aToken}`,
            },
          },
        )

        .then(res => {
          const data = res.data

          if (data.success) {
            commit('SAVE_NOTIFICATIONS', data.result)
          } else {
            eventBus.$emit('showToast', {
              title: res.response.message,
              variant: 'warning',
              icon: 'AlertTriangleIcon',
            })
          }
        })

        .catch(err => {
          console.error(err)

          eventBus.$emit('showToast', {
            title: 'Unexpected Error',
            variant: 'danger',
            icon: 'AlertOctagonIcon',
          })
        })
    },

    deleteNotification({ commit }, { params, aToken }) {
      localAxios
        .delete(
          process.env.VUE_APP_NOTIFICATIONS,
          {
            headers: {
              Authorization: `Bearer ${aToken}`,
            },
            data: params,
          },
        )

        .then(res => {
          const data = res.data

          if (data.success) {
            commit('DELETE_NOTIFICATION', params)
            commit('HIGHLIGHT_WATCHLIST', params[0])
          } else {
            eventBus.$emit('showToast', {
              title: res.response.message,
              variant: 'warning',
              icon: 'AlertTriangleIcon',
            })
          }
        })

        .catch(err => {
          console.error(err)

          eventBus.$emit('showToast', {
            title: 'Unexpected Error',
            variant: 'danger',
            icon: 'AlertOctagonIcon',
          })
        })
    },

    deleteAllNotifications({ state, commit }, aToken) {
      localAxios
        .delete(
          process.env.VUE_APP_NOTIFICATIONS,
          {
            headers: {
              Authorization: `Bearer ${aToken}`,
            },
            data: state.notifications,
          },
        )

        .then(res => {
          const data = res.data

          if (data.success) {
            commit('DELETE_ALL_NOTIFICATIONS')
          } else {
            eventBus.$emit('showToast', {
              title: res.response.message,
              variant: 'warning',
              icon: 'AlertTriangleIcon',
            })
          }
        })

        .catch(err => {
          console.error(err)

          eventBus.$emit('showToast', {
            title: 'Unexpected Error',
            variant: 'danger',
            icon: 'AlertOctagonIcon',
          })
        })
    },

  },
}
