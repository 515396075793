(function (root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['exports', 'echarts'], factory)
  } else if (typeof exports === 'object' && typeof exports.nodeName !== 'string') {
    // CommonJS
    factory(exports, require('echarts'))
  } else {
    // Browser globals
    factory({}, root.echarts)
  }
}(this, (exports, echarts) => {
  const log = function (msg) {
    if (typeof console !== 'undefined') {
      console && console.error && console.error(msg)
    }
  }
  if (!echarts) {
    log('ECharts is not Loaded')
    return
  }

  // Dublicated from @core/scss/base/components/_variables-dark.scss
  const pallete = {
    // Body
    '$theme-dark-body-bg': '#161d31',
    '$theme-dark-body-color': '#b4b7bd',
    '$theme-dark-border-color': '#3b4253',
    '$theme-dark-custom-control-border-color': '#44405e',
    '$theme-dark-light-color': '#555555',

    // Typography
    '$theme-dark-headings-color': '#d0d2d6',
    '$theme-dark-label-color': '#d0d2d6',
    '$theme-dark-text-muted-color': '#676d7d',

    // Card
    '$theme-dark-card-bg': '#283046',
    '$theme-dark-box-shadow': '#0 4px 24px 0 rgba(0,0,0, 0.24)',

    // Input
    '$theme-dark-input-bg': '#283046',
    '$theme-dark-input-placeholder-color': '#676d7d',
    '$theme-dark-input-border-color': '#404656',
    '$theme-dark-input-disabled-bg': '#24233a',
    '$theme-dark-input-disabled-border-color': '#444b60',

    // Switch
    '$theme-dark-switch-bg': '#545a6a',
    '$theme-dark-switch-bg-disabled': '#1b2337',

    // Table
    '$theme-dark-table-bg': '#283046',
    '$theme-dark-table-header-bg': '#343d55',
    '$theme-dark-table-row-bg': '#283046',
    '$theme-dark-table-hover-bg': '#242b3d',
    '$theme-dark-table-striped-bg': '#242b3d',

    '$theme-dark-modal-header-bg': '#161d31',
    '$theme-dark-pagination-bg': '#242b3d',
    '$theme-dark-chart-bg': '#384056',
    '$theme-dark-widget-bg': '#384056',

    // Additional
    '$theme-dark-white-color': '#ffffff',
  }

  const theme = {
    title: {
      show: false,
    },
    valueAxis: {
      show: false,
    },
    categoryAxis: {
      boundaryGap: false,
      show: false,
    },
    line: {
      hoverAnimation: false,
      showSymbol: false,
      smooth: true,
      lineStyle: {
        width: 1.5,
      },
      markLine: {
        lineStyle: {
          color: pallete['$theme-dark-input-border-color'],
          type: 'dotted',
        },
      },
    },
    tooltip: {
      show: false,
    },
    legend: {
      show: false,
    },
    toolbox: {
      show: false,
    },
    axisPointer: {
      show: false,
    },
    dataZoom: {
      disabled: true,
    },
    grid: {
      show: true,
      borderWidth: 0,
      left: 0,
      right: 0,
      top: 0,
      height: '100%',
    },
    textStyle: {
      color: pallete['$theme-dark-body-color'],
      fontFamily: '"Rubik", Helvetica, Arial, serif',
    },
    animation: false,
    animationDuration: 500,
    animationEasing: 'linear',
    backgroundColor: 'rgba(115, 103, 240, 0.04)',
  }
  echarts.registerTheme('dark-mini', theme)
}))
