<template>
  <ul class="itemlist list-unstyled">
    <li
      v-for="(item, index) in data"
      :key="index"
      class="itemlist__item pt-75 pb-75 no-events"
      :class="`font-weight-${weight}`">
      <feather-icon
        :icon="icon"
        class="mt-1 mr-50"
        :class="`text-${variant}`" />
      <!-- eslint-disable vue/no-v-html -->
      <span
        :style="fontSize"
        v-html="item.text" />
    </li>
  </ul>
</template>

<script>
export default {
  name: 'AppItemList',
  components: {
    
  },
  props: {
    data: {
      type: Array,
      default() {
        return []
      },
    },
    icon: {
      type: String,
      default: () => 'ArrowRightCircleIcon',
    },
    variant: {
      type: String,
      default: () => 'primary',
    },
    weight: {
      type: String,
      default: () => 'bold',
    },
    size: {
      type: String,
      default: () => 'normal',
    },
  },
  data() {
    return {
      
    }
  },
  computed: {
    fontSize() {
      return this.size === 'normal' ? 'font-size: 1rem' : 'font-size: 0.857rem'
    },
  },
  mounted() {
    
  },
  methods: {
    
  },
}
</script>

<style lang="scss">
@import '@core/scss/base/colors';
@import '@core/scss/base/components/_variables-dark';

.itemlist__item {
  position: relative;
  padding-left: 24px;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-top-color: $gray-200;

  &:last-child {
    border-bottom-color: $gray-200;
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
  }
}

body.dark-layout {
  .itemlist__item {
    border-top-color: $theme-dark-border-color;

    &:last-child {
      border-bottom-color: $theme-dark-border-color;
    }
  }
}
</style>
